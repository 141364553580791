import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { forEach, map, isEmpty } from 'lodash'
import { FaUserCircle, FaEdit, FaTimes } from "react-icons/fa"
import {  makeArrayKeyLabel, years, createNotification } from "../../utils/helpers"
import { addEditExperience } from "../../validations/user"
import { addExperience, removeExperience } from "../../actions/user"
import { getUser  } from "../../actions/auth"
import lang from "../../langs"
import BoxCard from '../Common/BoxCard'
import RadioBox from "../Common/RadioBox"
import { monthNames } from "../../utils/entries"

class WorkExperience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding:false,
            loading:false,
            saving:false,
            removing:false,
            edit:null,
            new:{
                title:'',
                company:'',
                compType:'',
                level:'',
                relation:'',
                from_mo:'',
                from_yr:'',
                to_mo:'',
                to_yr:'',
                desc:''
            },
            current_work:false,
            errors: {}
        };
    }

    componentDidMount() {
        
    }

    setCurrentWork(e) {
        this.setState({current_work:!this.state.current_work})
    }

    onChange(e) {
        this.setState({new:{
            ...this.state.new,
            [e.target.name]:e.target.value
        }})
    }

    onAdd(e) {
        this.setState({adding:!this.state.adding})
    }

    onEdit(item, e) {
        if( this.state.edit && this.state.edit === item.jobseekers_work_id ) {
            this.makeEmptyFields()
        } else {
            const from_mo = item.jobseekers_work_from_month > 10 ? String(item.jobseekers_work_from_month) : `0${item.jobseekers_work_from_month}`
            const to_mo = item.jobseekers_work_until_month > 10 ? String(item.jobseekers_work_until_month) : `0${item.jobseekers_work_until_month}`
            this.setState({
                current_work:item.jobseekers_work_present == "0" ? false : true,
                edit:item.jobseekers_work_id,
                new:{
                    title:item.jobseekers_work_title,
                    company:item.jobseekers_work_company,
                    compType:String(item.jobseekers_work_company_type),
                    level:'',
                    relation:'',
                    from_mo:from_mo,
                    from_yr:String(item.jobseekers_work_from_year),
                    to_mo:to_mo,
                    to_yr:String(item.jobseekers_work_until_year),
                    desc:item.jobseekers_work_description
                },
            })
        }
        
    }

    async onSubmit(e) {
        e.preventDefault();
        const userData = {
            ...this.state.new,
            current: this.state.current_work
        };

        try {
            await addEditExperience(userData)
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }

        this.setState({ errors: {}, loading:true, saving:true }, ()=>{
            let data = {
                jobseekers_work_title:this.state.new.title,
                jobseekers_work_company:this.state.new.company,
                jobseekers_work_company_type:this.state.new.compType,
                jobseekers_work_from_month:this.state.new.from_mo,
                jobseekers_work_from_year:this.state.new.from_yr,
                jobseekers_work_until_month:this.state.new.to_mo,
                jobseekers_work_until_year:this.state.new.to_yr,
                jobseekers_work_description:this.state.new.desc,
                jobseekers_work_present:this.state.current_work
            }

            if( this.state.edit && parseInt(this.state.edit) > 0 ) {
                data.jobseekers_work_id = this.state.edit
            }

            this.props.addExperience(data).then(res=>{
                this.setState({loading:false, adding:false,saving:false}, ()=>{
                    this.props.getUser()
                    this.makeEmptyFields()
                })
                
            }).catch(err=>{

            })
        })
    }

    onDelete(e) {
        this.setState({ errors: {}, loading:true, removing:true }, ()=>{
            this.props.removeExperience({
                jobseekers_work_id:this.state.edit
            }).then(res=>{
                this.setState({loading:false, adding:false, removing:false}, ()=>{
                    this.props.getUser()
                    this.makeEmptyFields()
                })
            }).catch(err=>{

            })
        })
    }


    makeEmptyFields() {
        this.setState({
            current_work:false,
            edit:null,
            new: {
                title:'',
                company:'',
                compType:'',
                level:'',
                relation:'',
                from_mo:'',
                from_yr:'',
                to_mo:'',
                to_yr:'',
                desc:''
            }
        })
    }

    render() {
       
        if( ! this.props.list ) {
            return null
        }

        const { errors } = this.state
       
        const form = (state='new') => (<li style={{zIndex:100}} className="shadow list-group-item bg-light align-items-center">
                <div className="mb-3 p-2">
                    <div className="form-group row">
                        <div className="col-12">
                            <label htmlFor="">Job title</label>
                            <input type="text" name="title" 
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.title
                                })}
                                value={this.state.new.title}
                                onChange={this.onChange.bind(this)} 
                                id="jobseekers_work_title" />

                            {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-6">
                            <label htmlFor="jobseekers_work_company">Company Name</label>
                            <input type="text" name="company" 
                            onChange={this.onChange.bind(this)} 
                            id="jobseekers_work_company" 
                            value={this.state.new.company}
                            className={classnames('form-control input-lg', {
                                'is-invalid': errors.company
                            })} />
                            {errors.company && <div className="invalid-feedback">{errors.company}</div>}
                        </div>
                        <div className="col-6">
                            <label htmlFor="jobseekers_work_type">Business Field</label>
                            <select name="compType" onChange={this.onChange.bind(this)} 
                                id="jobseekers_work_type" 
                                value={this.state.new.compType}
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.compType
                                })}
                            >
                                <option selected disabled value="">--Please type of business--</option>
                                {this.props.compTypes.map((i, d) => <option key={d} value={i.id}>{i.nama}</option> )}
                            </select>
                            {errors.compType && <div className="invalid-feedback">{errors.compType}</div>}
                        </div>
                    </div>

                    {/*<div className="form-group row">
                        <div className="col-6">
                            <h4>Level</h4>
                            {errors.level && <div className="alert alert-danger">
                                {errors.level}
                            </div>}
                            {makeArrayKeyLabel(this.props.workLevels).map((it,i) => <RadioBox onClick={this.onChange.bind(this)} value={it.key} selected={this.state.new.level} name={'level'} id={it.key} key={it.key} label={it.label} />) }
                        </div>

                        <div className="col-6">
                            <h4>Relation</h4>
                            {errors.relation && <div className="alert alert-danger">
                                {errors.relation}
                            </div>}
                            {makeArrayKeyLabel(this.props.workRelations).map((it,i) => <RadioBox onClick={this.onChange.bind(this)} selected={this.state.new.relation} value={it.key} name={'relation'} id={it.key} key={it.key} label={it.label} />) }
                        </div>
                    </div>*/}

                    <div className="form-group row">
                        <div className="col-12 col-lg-6">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="from">From</label>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <select name="from_mo" 
                                    defaultValue={this.state.new.from_mo}
                                    onChange={this.onChange.bind(this)} id="from_month" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.from_mo
                                    })}>
                                        <option disabled value="">--Choose month--</option>
                                        {monthNames().map((it,i)=> <option key={it.key} value={it.key}>{it.label}</option>)}
                                    </select>
                                    {errors.from_mo && <div className="invalid-feedback">{errors.from_mo}</div>}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <select name="from_yr" 
                                    defaultValue={this.state.new.from_yr}
                                    onChange={this.onChange.bind(this)} 
                                    id="from_year" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.from_yr
                                    })}>
                                        <option disabled value="">--Choose year--</option>
                                        {makeArrayKeyLabel(years()).map((it,i)=> <option key={i} value={it.label}>{it.label}</option>)}
                                    </select>
                                    {errors.from_yr && <div className="invalid-feedback">{errors.from_yr}</div>}
                                </div>
                            </div>
                        </div>

                        {this.state.current_work == false && <div className="col-12 col-lg-6">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="to">To</label>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <select name="to_mo" 
                                    defaultValue={this.state.new.to_mo}
                                    onChange={this.onChange.bind(this)} id="to_month" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.to_mo
                                    })}>
                                        <option disabled value="">--Choose month--</option>
                                        {monthNames().map((it,i) => <option key={it.key} value={it.key}>{it.label}</option>)}
                                    </select>
                                    {errors.to_mo && <div className="invalid-feedback">{errors.to_mo}</div>}
                                </div>
                                <div className="col-12 col-lg-6">
                                    <select name="to_yr" 
                                    defaultValue={this.state.new.to_yr}
                                    onChange={this.onChange.bind(this)} id="to_year" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.to_yr
                                    })}>
                                        <option disabled value="">--Choose year--</option>
                                        {makeArrayKeyLabel(years()).map((it,i)=> <option key={i} value={it.label}>{it.label}</option>)}
                                    </select>
                                    {errors.to_yr && <div className="invalid-feedback">{errors.to_yr}</div>}
                                </div>
                            </div>
                        </div>}

                    </div>

                    <div className="form-group row">
                        <div className="col-12">
                            <RadioBox value={'current'} selected={this.state.current_work ? 'current' : false} onClick={this.setCurrentWork.bind(this)} name={'current_work'} id={'current_work'} label={'Currently work here'} />
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-12">
                            <textarea name="desc"  value={this.state.new.desc} onChange={this.onChange.bind(this)}  className={"form-control"} placeholder={"Please describe your job title"} id="" cols="30" rows="5"></textarea>
                        </div>
                    </div>

                    <div className="form-group row mt-2">
                        <div className="col-12 col-lg-2">
                            <button type="button" disabled={this.state.saving ? true : false} onClick={this.onSubmit.bind(this)} name="doSubmit" id="submitExp" className={classnames("btn btn-block btn-danger", {
                                "d-none":this.state.removing == true
                            })}>{this.state.loading ? 'Saving...' : 'Save'}&nbsp;</button>
                        </div>
                        {state == 'edit' && <div className="col-12 col-lg-2">
                            <button type="button" onClick={()=>this.makeEmptyFields()} name="doCancel" id="cancelExp" 
                            className={classnames("btn btn-secondary", {
                                "d-none":this.state.saving === true || this.state.removing === true
                            })}>{'Cancel'}&nbsp;</button>
                        </div>}
                        {state == 'edit' && <div className="col-12 col-lg-8  d-flex align-self-end justify-content-end">
                            <button type="button" disabled={this.state.removing ? true : false} onClick={this.onDelete.bind(this)} name="doRemove" id="removeExp" className={classnames("btn btn-outline-dark", {
                                "d-none":this.state.saving == true
                            })}>{this.state.loading ? 'Removing...' : 'Remove'}</button>
                        </div>}
                    </div>

                </div>
            </li>)

        return (
        <BoxCard title={'Work Experience'} width="12" withAction={true} stateAction={this.state.adding} onAction={this.onAdd.bind(this)}>
            <ul className="list-group border-none ml-0">
                {this.state.adding && form()}
                {this.props.list && this.props.list.map(item => (
                    <React.Fragment key={item.jobseekers_work_id}>
                        <li key={item.jobseekers_work_id} className="list-group-item align-items-center">
                            <h4 className="mb-1">{item.jobseekers_work_title}</h4>
                            <p className="font-weight-bold mb-1">{item.jobseekers_work_company}</p>
                            <p className="text-muted mb-1">{item.begin_date} s/d {item.exit_date} ( {item.length_of_experience} ) </p>
                            {item.jobseekers_work_description && <p style={{fontSize:14}} className="text-muted mb-1">{item.jobseekers_work_description}</p>}
                            <span onClick={this.onEdit.bind(this, item)} style={{
                                position:"absolute",
                                top:20,
                                right:20,
                                cursor:"pointer"
                            }}>
                                {this.state.edit !== item.jobseekers_work_id && <FaEdit size={18} color={"#999"} />}
                                {this.state.edit === item.jobseekers_work_id && <FaTimes size={18} color={"#999"} />}
                            </span>
                        </li>
                        {(this.state.edit === item.jobseekers_work_id) && form('edit')}
                    </React.Fragment>
                ))}

                {!this.props.list || isEmpty(this.props.list) && <li className="list-group-item d-flex justify-content-center align-items-center">
                    <p className="" style={{padding:'30px 0px'}}>
                        No work experience list. Create one.
                    </p>
                </li>}
            </ul>
        </BoxCard>)

    }

}


WorkExperience.propTypes = {
    errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors
});


export default connect(mapStateToProps, { addExperience, getUser })(WorkExperience);