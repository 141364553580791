import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { map, isEmpty } from 'lodash'
import { FaEdit, FaTimes } from "react-icons/fa"
import {  makeArrayKeyLabel, years, createNotification } from "../../utils/helpers"
import { addEditEducationValidate } from "../../validations/user"
import { addEducation, removeEducation } from "../../actions/user"
import { getCities } from "../../actions/category"
import { getUser  } from "../../actions/auth"
import BoxCard from '../Common/BoxCard'

class Education extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding:false,
            loading:false,
            saving:false,
            removing:false,
            edit:null,
            new:{
                name:'',
                province:'',
                city:'',
                from:'',
                to:'',
                degree:'',
                faculty:'',
                specialist:'',
            },
            loadCities:false,
            cities:null,
            current_work:false,
            errors: {}
        };
    }

    componentDidMount() {
        
    }

    onChange(e) {
        this.setState({new:{
            ...this.state.new,
            [e.target.name]:e.target.value
        }})
    }

    onAdd(e) {
        this.setState({adding:!this.state.adding})
    }

    onProvinceSelect(e) {
        const code = e.target.value
        this.setState({loadCities:true, new:{
            ...this.state.new,
            province:code
        }}, ()=>{
            this.props.getCities(code)
                .then(res=>{
                    this.setState({
                        loadCities:false,
                        cities:res
                    })
                })
        })
        
    }

    onEdit(item, e) {
        if( this.state.edit && this.state.edit === item.jobseekers_education_id ) {
            this.makeEmptyFields()
        } else {
            this.setState({
                edit:item.jobseekers_education_id,
                new:{
                    name:item.jobseekers_education_institution,
                    province:item.jobseekers_education_location,
                    city:String(item.jobseekers_education_location_city),
                    degree:item.jobseekers_education_degree,
                    faculty:item.jobseekers_education_faculty,
                    from:String(item.jobseekers_education_years),
                    to:String(item.jobseekers_education_to_years),
                },
            })
        }
        
    }

    async onSubmit(e) {
        e.preventDefault();
        const userData = {
            ...this.state.new,
        };

        try {
            await addEditEducationValidate(userData)
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }

        this.setState({ errors: {}, loading:true, saving:true }, ()=>{
            let data = {
                jobseekers_education_institution:this.state.new.name,
                jobseekers_education_degree:this.state.new.degree,
                jobseekers_education_faculty:this.state.new.faculty,
                from_year:parseInt(this.state.new.from),
                to_year:parseInt(this.state.new.to)
            }

            if( this.state.edit && parseInt(this.state.edit) > 0 ) {
                data.jobseekers_education_id = this.state.edit
            }

            this.props.addEducation(data).then(res=>{
                this.setState({loading:false, saving:false, adding:false}, ()=>{
                    this.props.getUser()
                    this.makeEmptyFields()
                })
                
            }).catch(err=>{

            })
        })
    }

    onDelete(e) {
        this.setState({ errors: {}, loading:true, removing:true }, ()=>{
            this.props.removeEducation({
                jobseekers_education_id:this.state.edit
            }).then(res=>{
                this.setState({loading:false, adding:false, removing:false }, ()=>{
                    this.props.getUser()
                    this.makeEmptyFields()
                })
            }).catch(err=>{

            })
        })
    }


    makeEmptyFields() {
        this.setState({
            edit:null,
            new: {
                name:'',
                province:'',
                city:'',
                from:'',
                to:'',
                degree:'',
                faculty:'',
                specialist:'',
            }
        })
    }

    render() {
      
        const { errors, cities, loadCities } = this.state

        const form = (state='new') => (<li style={{zIndex:100}} className="shadow list-group-item bg-light align-items-center">
                <div className="mb-3 p-2">
                    <div className="form-group row">
                        <div className="col-12">
                            <label htmlFor="school">School or University</label>
                            <input type="text" name="name" 
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.name
                                })}
                                value={this.state.new.name}
                                onChange={this.onChange.bind(this)} 
                                id="school" />

                            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>
                    </div>

                    {/*<div className="form-group row">
                        <div className="col-6">
                            <label htmlFor="jobseekers_work_company">Province</label>
                            <select name="province" onChange={this.onProvinceSelect.bind(this)} 
                                id="province" 
                                value={this.state.new.province}
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.province
                                })}
                            >
                                <option disabled value="">--Please province--</option>
                                {this.props.provinces.map((i, d) => <option value={i.lokasi_kode}>{i.lokasi_nama}</option> )}
                            </select>
                            {errors.province && <div className="invalid-feedback">{errors.province}</div>}
                        </div>
                        <div className="col-6">
                            <label htmlFor="cities">City</label>
                            <select name="city" onChange={this.onChange.bind(this)} 
                                id="cities" 
                                value={this.state.new.city}
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.city
                                })}
                            >
                                <option disabled value="">--Please select city--</option>
                                {cities && cities.length > 0  && cities.map((i, d) => <option value={i.lokasi_kode}>{i.lokasi_nama}</option> )}
                            </select>
                            {loadCities && <div className="spinner-circle" style={{ width:'1rem', height:'1rem', position:"absolute", right:10, top: 20 }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>}
                            {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                        </div>
                            </div>*/}

                    <div className="form-group row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="from">From</label>
                                </div>
                                
                                <div className="col-12">
                                    <select name="from" 
                                    defaultValue={this.state.new.from}
                                    onChange={this.onChange.bind(this)} 
                                    id="from_year" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.from
                                    })}>
                                        <option disabled value="">--Choose year--</option>
                                        {makeArrayKeyLabel(years()).map((it,i)=> <option value={it.label}>{it.label}</option>)}
                                    </select>
                                    {errors.from && <div className="invalid-feedback">{errors.from}</div>}
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="to">To</label>
                                </div>
                                
                                <div className="col-12">
                                    <select name="to" 
                                    defaultValue={this.state.new.to}
                                    onChange={this.onChange.bind(this)} id="to_year" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.to
                                    })}>
                                        <option disabled value="">--Choose year--</option>
                                        {makeArrayKeyLabel(years()).map((it,i)=> <option value={it.label}>{it.label}</option>)}
                                    </select>
                                    {errors.to && <div className="invalid-feedback">{errors.to}</div>}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group row">
                        <div className="col-6">
                            <select name="degree" 
                                defaultValue={this.state.new.degree}
                                onChange={this.onChange.bind(this)} 
                                id="degree" 
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.degree
                                })}
                            >
                                <option disabled value="">--Choose degree--</option>
                                {this.props.degrees.map((it,i)=> <option value={it.nama}>{it.nama}</option>)}
                            </select>
                            {errors.degree && <div className="invalid-feedback">{errors.degree}</div>}
                        </div>
                        {this.state.new.degree && this.state.new.degree !== 'SMU/SMK/STM'  && <div className="col-6">
                            <select name="faculty" 
                                defaultValue={this.state.new.faculty}
                                onChange={this.onChange.bind(this)} 
                                id="faculty" 
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.faculty
                                })}
                            >
                                <option disabled value="">--Choose faculty--</option>
                                {this.props.faculties.map((it,i)=> <option value={it.nama}>{it.nama}</option>)}
                            </select>
                            {errors.faculty && <div className="invalid-feedback">{errors.faculty}</div>}
                        </div>}
                    </div>

                    <div className="form-group row mt-2">
                        <div className="col-2">
                            <button type="button" disabled={this.state.saving ? true : false} onClick={this.onSubmit.bind(this)} name="doSubmit" id="submitExp" className={classnames("btn btn-block btn-danger", {
                                "d-none":this.state.removing == true
                            })}>{this.state.loading ? 'Saving...' : 'Save'}&nbsp;</button>
                        </div>
                        {state == 'edit' && <div className="col-2">
                            <button type="button" onClick={()=>this.makeEmptyFields()} name="doCancel" id="cancelExp" 
                            className={classnames("btn btn-secondary", {
                                "d-none":this.state.saving === true || this.state.removing === true
                            })}>{'Cancel'}&nbsp;</button>
                        </div>}
                        {state == 'edit' && <div className="col-8  d-flex align-self-end justify-content-end">
                            <button type="button" disabled={this.state.removing ? true : false} onClick={this.onDelete.bind(this)} name="doRemove" id="removeExp" className={classnames("btn btn-outline-dark", {
                                "d-none":this.state.saving == true
                            })}>{this.state.loading ? 'Removing...' : 'Remove'}</button>
                        </div>}
                    </div>
                </div>
            </li>)

        return (
        <BoxCard title={'Education'} width="12" withAction={true} stateAction={this.state.adding} onAction={this.onAdd.bind(this)}>
            <ul className="list-group border-none ml-0">
                {this.state.adding && form()}
                {this.props.list && this.props.list.map(item => (
                    <React.Fragment key={item.jobseekers_education_id}>
                        <li className="list-group-item align-items-center">
                            <h4 className="mb-1">{item.jobseekers_education_degree}</h4>
                            <p className="font-weight-bold mb-1">{item.jobseekers_education_institution}{item.jobseekers_education_faculty && <span> - {item.jobseekers_education_faculty}</span>}</p>
                            <p className="text-muted mb-1">{item.jobseekers_education_years} s/d {item.jobseekers_education_to_years}</p>
                            
                            <span onClick={this.onEdit.bind(this, item)} style={{
                                position:"absolute",
                                top:20,
                                right:20,
                                cursor:"pointer"
                            }}>
                                {this.state.edit !== item.jobseekers_education_id && <FaEdit size={18} color={"#999"} />}
                                {this.state.edit === item.jobseekers_education_id && <FaTimes size={18} color={"#999"} />}
                            </span>
                        </li>
                        {(this.state.edit === item.jobseekers_education_id) && form('edit')}
                    </React.Fragment>
                ))}
                {!this.props.list || isEmpty(this.props.list) && <li className="list-group-item d-flex justify-content-center align-items-center">
                        <p className="" style={{padding:'30px 0px'}}>
                            No education list. Create one.
                        </p>
                </li> }
            </ul>
        </BoxCard>)

    }

}


Education.propTypes = {
    errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors
});


export default connect(mapStateToProps, { addEducation, removeEducation, getUser, getCities })(Education);