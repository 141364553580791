import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { makeArrayKeyLabel, years, createNotification, diffYears, displayAvatar } from "../../utils/helpers"
import { FaUpload } from "react-icons/fa"
import { resumeUpladed } from "../../actions/user"
import { restDropzone } from "../../utils/rest"
import { dateNumber } from "../../utils/entries"
import Dropzone from "react-dropzone-uploader"
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import { truncate } from "lodash"

class ResumeUpload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            file:props.file
        }
    }

    uploadChangeStatus = ({ meta, xhr, remove }, status) => {
        if (status === 'headers_received') {
            remove()
        } else if( status == 'exception_upload' ) {
            createNotification('error', 'No connection when upload. Please check your internet connection')
        } else if( status == 'error_upload' ) {
            createNotification('error', 'Failed upload!')
        } else if (status === 'preparing') {
            if( this.props.maxSize > meta.size ) {
                this.setState({
                    file:null
                })
            }
            
        } else if (status === 'done') {
            const resp = xhr.response
            const response = JSON.parse(resp)
            if( response.error === false ) {
                this.props.resumeUpladed(response.user.jobseekers_resume)
                createNotification('success', 'Your CV is uploaded')
                this.setState({
                    file:response.user.jobseekers_resume
                })
              
            }         
        }
    }

    getUploadParams = ({ file, meta }) => {
        const body = new FormData
        body.append('resume', file)
        return restDropzone('me/update-file', body)
    }

    handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }

    render() {
        const { maxSize } = this.props

        const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
            return (
                <div className="input-upload">
                    <label className="shadow mt-2 justify-content-center py-2 px-3 align-items-center" style={{ cursor: 'pointer', borderRadius: 30 }}>
                        <FaUpload size={20} /> {this.state.file ? 'Update CV' : 'Upload CV'}
                        <input
                            style={{ display: 'none' }}
                            type="file"
                            accept={accept}
                            onChange={e => {
                                this.getFilesFromEvent(e).then(chosenFiles => {
                                    onFiles(chosenFiles)
                                })
                            }}
                        />
                    </label>
                </div>
            )
        }

        const CustomDropzoneLayout = ({ previews, submitButton, input, files, dropzoneProps }) => {
            const { ref, className, style } = dropzoneProps
            return (
                <>
                    <div ref={ref} className={className} style={style}>
                        {previews}{this.state.file && <h5 className="my-2 mt-3"><span class="badge badge-pill badge-warning">{truncate(this.state.file, {length:38})}</span></h5>}{input} 
                ​    </div>
                    <small><em>- Allow upload CV (pdf, jpeg) Max. 4 MB</em></small> <br/>
                    <small><em>- Please make short name for document ( max. 36 chars )</em></small>
                </>
            )
        }

        return(
            <div className="mt-3">
                <Dropzone 
                    getUploadParams={this.getUploadParams}
                    onChangeStatus={this.uploadChangeStatus.bind(this)}
                    onSubmit={this.handleSubmit}
                    maxFiles={1}
                    maxSizeBytes={maxSize}
                    accept="application/pdf,image/jpeg"
                    InputComponent={Input}
                    LayoutComponent={CustomDropzoneLayout}
                    multiple={false}
                    canCancel={false}
                    styles={{
                        dropzoneActive: { borderColor: 'green' }  
                    }}>
                </Dropzone>
            </div>
            
        )
    }
}

ResumeUpload.defaultProps = {
    maxSize:(1048576*4)
}

ResumeUpload.propTypes = {
    maxSize:PropTypes.number
} 
const mapStateToProps = state => ({
    errors: state.errors
});

export default connect(mapStateToProps, {resumeUpladed})(ResumeUpload)