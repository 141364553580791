import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getApplications } from '../../actions/user';
import { getUser } from '../../actions/auth';
import UserContext from "../../context/CurrentUserContext"
import UserLayout from "../UserLayout"
import SEO from "../seo"
import MyApplication from "../User/MyApplication"
import ItemListLoader from "../Loader/ItemListLoader"
import BoxCard from "../Common/BoxCard"
import SidebarMenu from "../sidebarMenu"

class Applications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            errors: {}
        };
    }

    componentDidMount() {
       this.props.getApplications()
       this.props.getUser()
    }

    render() {
        const { errors } = this.state;

        if (!this.props.applications && !this.props.profile) {
            return (<UserLayout>
                
                <BoxCard bgcolor="bg-light" styles={{color:'#333'}} title="My Application">
                    <ItemListLoader />
                    <ItemListLoader />
                    <ItemListLoader />
                    <ItemListLoader />
                </BoxCard>
               
            </UserLayout>)
        }
        
        return (
            <UserLayout page="user">
                <SEO title="User Applications" />
                <UserContext.Consumer>
                    {cuser => (<div className="container" id="bodyApplications">
                        <div className="align-items-center justify-content-center w-auto-xs bg-white">
                            <div className="row">
                                <div className="col-10">
                                    <MyApplication list={this.props.applications} /> 
                                </div>
                                <div className="col-2 justify-content-right">
                                    <SidebarMenu message={this.props.profile.last_message} active="application" />
                                </div>
                            </div>

                           
                        </div>
                    </div>)}
                </UserContext.Consumer>
            </UserLayout>
        )
    }
}

Applications.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    applications: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile,
    applications: state.user.applications
});

export default connect(mapStateToProps, { getApplications, getUser })(Applications);
