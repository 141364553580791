/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import PrivateHeader from "./PrivateHeader"
import Footer from "./footer"
import "./layout.css"

const UserLayout = ({ page, children, withHeader=true }) => {
  const data2 = useStaticQuery(graphql`
    query UserSiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <PrivateHeader siteTitle={data2.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1260,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>
      </div>

      <Footer />
    </>
  )
}

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserLayout
