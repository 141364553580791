import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from "gatsby"
import { connect } from 'react-redux'
import { forEach, map, isEmpty } from 'lodash'
import { FaUserCircle, FaEdit, FaTimes } from "react-icons/fa"
import {  makeArrayKeyLabel, years, createNotification } from "../../utils/helpers"
import { changePasswordValidate } from "../../validations/user"
import { changePassword } from "../../actions/user"
import { getUser  } from "../../actions/auth"
import lang from "../../langs"
import BoxCard from '../Common/BoxCard'
import RadioBox from "../Common/RadioBox"

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding:false,
            loading:false,
            saving:false,
            removing:false,
            message:'',
            old:'',
            new:'',
            confirm:'',
            name:'',
            errors: {}
        };
    }

    componentDidMount() {
        
    }

    onChange(e) {
        this.setState({[e.target.name]:e.target.value})
    }

    onSubmitChange(e) {
        const userData = {
            old_password:this.state.old,
            new_password:this.state.new,
            new_password_confirmation:this.state.confirm
        }

        try {
            changePasswordValidate(userData).then(succ=>{

                this.setState({ errors:{} })

                this.setState({loading:true}, ()=>{
                    this.props.changePassword(userData)
                    .then(res=>{
                        this.setState({
                            new:'',
                            old:'',
                            message:"Your password is changed! The page will be auto logout and redirect to login page",
                            confirm:""
                        })
                        setTimeout(()=>{
                            navigate('/user/app/logout')
                        },3500)
                    })
                    .catch(err=>{
                        this.setState({
                            loading:false
                        })
                        createNotification('error', err.data.messages);
                    })
                })

            }).catch(err=>{
                this.setState({ errors: err })
                createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
                return false;
            })
        } catch(err) {
            this.setState({ errors: err })
            createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }
    }

    makeEmptyFields() {
        this.setState({
            edit:null,
            name:''
        })
    }

    render() {
      
        const { errors } = this.state

        const form = (state='new') => (<div style={{zIndex:100}} className="align-items-center">
                {this.state.message !='' && <div className="alert alert-info">
                    {this.state.message}
                </div>}
                <div className="form-inline mb-3 p-2">
                    <div className="form-group row w-100 mb-3">
                        <label className="col-3 justify-content-start" htmlFor="old">Old password</label>
                        <input type="password" name="old" 
                            className={classnames('form-control col-6 input-lg', {
                                'is-invalid': errors.old
                            })}
                            disabled={this.state.loading}
                            style={{fontSize:20}}
                            value={this.state.old}
                            onChange={this.onChange.bind(this)} 
                            placeholder={"Current Password"}
                            id="old" />

                        {errors.old && <div className="invalid-feedback">{errors.old}</div>}
                    </div>
                            
                    <div className="form-group row w-100 mb-3">
                        <label className="col-3 justify-content-start" htmlFor="new">New password</label>
                        <input type="password" name="new" 
                            className={classnames('form-control col-6 input-lg', {
                                'is-invalid': errors.new
                            })}
                            disabled={this.state.loading}
                            style={{fontSize:20}}
                            value={this.state.new}
                            onChange={this.onChange.bind(this)} 
                            placeholder={"New Password"}
                            id="new" />

                        {errors.new && <div className="invalid-feedback">{errors.new}</div>}
                    </div>

                    <div className="form-group row w-100 mb-2">
                        <label className="col-3 justify-content-start" htmlFor="confirm">Confirm New password</label>
                        <input type="password" name="confirm" 
                            className={classnames('form-control col-6 input-lg', {
                                'is-invalid': errors.confirm
                            })}
                            disabled={this.state.loading}
                            style={{fontSize:20}}
                            value={this.state.confirm}
                            onChange={this.onChange.bind(this)} 
                            placeholder={"Enter Confirm New Password"}
                            id="confirm" />

                        {errors.confirm && <div className="invalid-feedback">{errors.confirm}</div>}
                    </div>


                    <div className="form-group row w-100 mb-2 mt-2">
                        <div className="col-3">&nbsp;</div>
                        <div className="col-6 pl-0">
                            <button type="button" disabled={this.state.loading} className="btn btn-danger btn-lg" onClick={this.onSubmitChange.bind(this)}>Update Password</button>
                        </div>
                    </div>
                </div>
            </div>)

        return (
        <BoxCard title={'Change Password'} styles={{border:'1px solid #ddd', marginTop:20 }} bgcolor={'bg-light'} width="12">
            <div className="ml-0">
                {form()}
            </div>
        </BoxCard>)

    }

}


ChangePassword.propTypes = {
    errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors
});


export default connect(mapStateToProps, { changePassword })(ChangePassword);