import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Link } from "gatsby"
import { connect } from 'react-redux'
import { createNotification, pageTitle, displayAvatar, diffYears } from '../../utils/helpers'
import { createPayment, successPayment, getSubscription } from "../../actions/payment"
import UserContext from "../../context/CurrentUserContext"
import UserLayout from "../UserLayout"
import Layout from '../layout'
import { FaUserCircle, FaCheckCircle, FaExclamationCircle } from "react-icons/fa"
import SEO from "../seo"
import SingleListLoader from "../Loader/SingleListLoader"
import BoxCard from "../Common/BoxCard"
import RadioBox from "../../components/Common/RadioBox"
import ItemListLoader from "../Loader/ItemListLoader"
import moment from "moment"
import PremiumImage from "../Images/PremiumImageLarge"
import WaitingImage from "../Images/WaitingImageLarge"

class Thank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            order:false,
            notfound:false,
            token:'',
            errors: {}
        }
    }

    componentDidMount() {
        let order_no = null
        const urlParams = new URLSearchParams(this.props.location.search);

        if( urlParams.get('order_id') ) {
            order_no = urlParams.get('order_id')
        }
        
        this.props.getSubscription(order_no)
            .then(res=>{

                if( res.data ) {
                    this.setState({
                        order:res.data
                    })
                } else {
                    this.setState({
                        order:res.data,
                        notfound:true
                    })
                }
                
            })
    }

    render() {
        const { errors } = this.state;
        const order = this.state.order;
        return (
            <UserLayout page="user">
                <SEO title="Thank You Payment" />
                <UserContext.Consumer>
                    {cuser => (<div className="container" id="thankPayment">
                        {(this.state.order && this.state.order.status == 'ok') && <BoxCard bgcolor="bg-light" styles={{color:'#333'}}>
                            <div className="row">
                                <div className="col-12 mt-3 text-center">
                                    <PremiumImage />
                                    <h3 className="mt-5 mb-4">Great! Your Subscription is successful</h3>
                                    <p className="lead">
                                        Thanks for subscription Membership Premium at Jobsmigas.com <br/>
                                        {_.upperCase(order.remark)}
                                    </p>

                                    <div className="mb-3 mt-3">
                                        <div className="p-3" style={{
                                            background:"antiquewhite",
                                            border:"1px solid bisque"
                                        }}>
                                            <strong>Order No</strong> <br />
                                            <h4>{order.trx_id}</h4>
                                            <p className="lead mb-0">{order.type} mulai tanggal {moment(order.start_date).format("DD MMM YYYY")} s.d. {moment(order.expiry_date).format("DD MMM YYYY")} </p>
                                        </div>
                                    </div>

                                    <p>If you have any help about payment please contact us <a className="text-primary" href="mailto:support@jobsmigas.com">support@jobsmigas.com</a>
                                    </p>
                                </div>
                            </div>

                           <div className="row mt-3 justify-content-center text-center">
                                <div className="col-12 border-top">
                                    <Link className="btn btn-outline-secondary btn-lg mt-3" to={'/user/dashboard'}>Close</Link>
                                </div>
                            </div>
                        </BoxCard>}

                        {(this.state.order && this.state.order.status == 'pending') && <BoxCard bgcolor="bg-light" styles={{color:'#333'}}>
                            <div className="row">
                                <div className="col-12 mt-3 text-center">
                                    <WaitingImage />
                                    <h3 className="mt-5 mb-4">Your Order is waiting confirmation</h3>
                                    <p className="lead">
                                        Thanks for subscription Membership Premium at Jobsmigas.com. <br/>
                                        {_.upperCase(order.remark)}
                                    </p>
                                    <h5 className="badge badge-warning">Waiting Confirmation</h5>

                                    <div className="mb-3 mt-3">
                                        <div className="p-3" style={{
                                            background:"antiquewhite",
                                            border:"1px solid bisque"
                                        }}>
                                            <strong>Order No</strong> <br />
                                            <h4>{order.trx_id}</h4>
                                            <p className="lead mb-0">{order.type} mulai tanggal {moment(order.start_date).format("DD MMM YYYY")} s.d. {moment(order.expiry_date).format("DD MMM YYYY")} </p>
                                        </div>
                                    </div>

                                    <p>If you have any questions please contact us <a className="text-primary" href="mailto:support@jobsmigas.com">support@jobsmigas.com</a>
                                    </p>
                                </div>
                            </div>

                           <div className="row mt-3 justify-content-center text-center">
                                <div className="col-12 border-top">
                                    <Link className="btn btn-outline-secondary btn-lg mt-3" to={'/user/dashboard'}>OK Back</Link>
                                </div>
                            </div>
                        </BoxCard>}

                        {(this.state.order && this.state.order.status == 'canceled') && <BoxCard bgcolor="bg-light" styles={{color:'#333'}}>
                            <div className="row">
                                <div className="col-12 mt-3 text-center">
                                    <FaExclamationCircle size="4em" color="#333" />
                                    <h3 className="mt-5 mb-4">Your Order is Failed</h3>
                                    <h4>{order.trx_id}</h4>
                                    <p className="lead">
                                        Failed Transaction <br/>
                                        {_.upperCase(order.remark)}
                                    </p>
                                    <h5 className="badge badge-danger">Failed Payment</h5>

                                    <div className="mb-3 mt-3">
                                        <div className="p-3" style={{
                                            background:"antiquewhite",
                                            border:"1px solid bisque"
                                        }}>
                                            <strong>Order No</strong> <br />
                                            <h4>{order.trx_id}</h4>
                                            
                                        </div>
                                    </div>

                                    <p>If you have any questions please contact us <a className="text-primary" href="mailto:support@jobsmigas.com">support@jobsmigas.com</a>
                                    </p>
                                </div>
                            </div>

                           <div className="row mt-3 justify-content-center text-center">
                                <div className="col-12 border-top">
                                    <Link className="btn btn-danger btn-lg mt-3" to={'/user/upgrade'}>Upgrade Membership</Link>
                                </div>
                            </div>
                        </BoxCard>}

                        {this.state.order == false && <BoxCard bgcolor="bg-light">
                            <ItemListLoader />
                            <ItemListLoader />
                            <ItemListLoader />
                        </BoxCard>}

                        {(this.state.order == null && this.state.notfound) && <BoxCard bgcolor="bg-light">
                            <div className="row">
                                <div className="col-12">
                                <h3 className="mt-5 mb-4">Order ID is Not Found</h3>
                                <p className="lead">
                                    Your order might had been expired or not matched
                                </p>
                                </div>
                            </div>
                        </BoxCard>}
                       
                    </div>)}
                </UserContext.Consumer>
            </UserLayout>
        )
    }
}

Thank.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    applications: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile,
    applications: state.user.applications
});

export default connect(mapStateToProps, { getSubscription })(Thank);
