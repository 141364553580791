import React from "react"
import { upperCase } from "lodash"

const RadioBox = ({id, name, value, label, selected, onClick}) => (
    <div className="mb-2">
        <div key={id} className="custom-control custom-radio">
            <input type="radio" onClick={onClick} checked={selected == value ? true:false} id={`customRadio` + id} name={name} value={value} className="custom-control-input" />
            <label className="custom-control-label" htmlFor={`customRadio` + id}>{label}</label>
        </div>
    </div>
)

export default RadioBox