import React from 'react';
import { connect } from 'react-redux';
import { navigate } from "gatsby"
import PropTypes from 'prop-types';

import { isNil } from 'lodash'

/*
const PrivateRoute = ({ component: Component, auth, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            auth.isAuthenticated === true ? (
                <UserLayout>
                    <Component {...props} />
                </UserLayout>
            ) : (
                    <Redirect to="/" />
                )
        }
    />
);
*/

const PrivateRoute = ({ component: Component, location, auth, ...rest }) => {
    if (auth.isAuthenticated === false && ( location.pathname !== `/login` && location.pathname !== `/register` ) ) {
      // If we’re not logged in, redirect to the home page.
      const urlParams = new URLSearchParams(location.search);
      if( urlParams.get('red') ) {
        if( urlParams.get('box_id') ) {
            navigate(`/login/?red=${urlParams.get('red')}&box_id=${urlParams.get('box_id')}`)
        } else {
            navigate(`/login/?red=${urlParams.get('red')}`)
        }
        
        return null
      }

      

      navigate(`/login`)
      return null
    } else {
        const urlParams = new URLSearchParams(location.search);
        if( urlParams.get('red') ) {
            location.search = ''
        }
    }
  
    return (<Component location={location} {...rest} />)
  }
  
PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
