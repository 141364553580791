import _ from 'lodash'
import { rest } from '../utils/rest'
import {
    GET_ERRORS,
    GET_APPLICATIONS,
    GET_SUBSCRIPTIONS,
    ADD_EXPERIENCE,
    DELETE_EXPERIENCE,
    ADD_EDUCATION,
    DELETE_EDUCATION,
    ADD_SKILL,
    DELETE_SKILL,
    ADD_LANGUAGE,
    DELETE_LANGUAGE,
    UPLOAD_PROFILE_IMAGE,
    UPDATE_PROFILE,
    SAVE_JOB_INTERESTING,
    CHANGE_PASSWORD,
    RESUME_UPLOADED,
    PP_IMAGE_UPLOADED
} from './types';

export const updateBasicInfo = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('me/updateinfo', args)
           .then(res=>{
               dispatch({
                   type:UPDATE_PROFILE,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}


export const uploadProfileImage = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('me/update-file', args)
           .then(res=>{
               dispatch({
                   type:UPLOAD_PROFILE_IMAGE,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const addExperience = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('me/works', args)
           .then(res=>{
               dispatch({
                   type:ADD_EXPERIENCE,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}


export const removeExperience = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .delete('me/works', {data:args})
           .then(res=>{
               dispatch({
                   type:DELETE_EXPERIENCE,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}


export const addEducation = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('me/educations/save', args)
           .then(res=>{
               dispatch({
                   type:ADD_EDUCATION,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}



export const removeEducation = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .delete('me/educations', {data:args})
           .then(res=>{
               dispatch({
                   type:DELETE_EDUCATION,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}


export const addSkill = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('me/skills', args)
           .then(res=>{
               dispatch({
                   type:ADD_SKILL,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const removeSkill = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .delete('me/skills', {data:args})
           .then(res=>{
               dispatch({
                   type:DELETE_SKILL,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const addLang = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('me/languages', args)
           .then(res=>{
               dispatch({
                   type:ADD_LANGUAGE,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const removeLang = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .delete('me/languages', {data:args})
           .then(res=>{
               dispatch({
                   type:DELETE_LANGUAGE,
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const getApplications = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .get('applications', {params:args})
           .then(res=>{
               dispatch({
                   type:GET_APPLICATIONS,
                   payload:res.data
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const getSubscriptions = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .get('subscriptions', {params:args})
           .then(res=>{
               dispatch({
                   type:GET_SUBSCRIPTIONS,
                   payload:res.data
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const saveJobInteresting = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('user/interesting', args)
           .then(res=>{
               dispatch({
                   type:SAVE_JOB_INTERESTING,
                   payload:res.data
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err)
           })
   })
}

export const changePassword = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('me/changePassword', args)
           .then(res=>{
               dispatch({
                   type:CHANGE_PASSWORD,
                   payload:res.data
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err.response)
           })
   })
}

export const resumeUpladed = (file) => dispatch => {
    dispatch({
        type:RESUME_UPLOADED,
        payload:file
    })
}

export const ppUpladed = (file) => dispatch => {
    dispatch({
        type:PP_IMAGE_UPLOADED,
        payload:file
    })
}