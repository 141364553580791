export const dateNumber = () => {
    const limit = 31
    let data = []
    for (let index = 0; index < limit; index++) {
        let number = index+1;
        if( number < 10 ) {
            data.push(`0${number}`)
        } else {
            data.push(`${number}`)
        }
       
    }
    return data
}

export const monthNames = () => {
    return [
        {
            key:'01',
            label:'January'
        },
        {
            key:'02',
            label:'February'
        },
        {
            key:'03',
            label:'March'
        },
        {
            key:'04',
            label:'April'
        },
        {
            key:'05',
            label:'May'
        },
        {
            key:'06',
            label:'June'
        },
        {
            key:'07',
            label:'July'
        },
        {
            key:'08',
            label:'August'
        },
        {
            key:'09',
            label:'September'
        },
        {
            key:'10',
            label:'October'
        },
        {
            key:'11',
            label:'November'
        },
        {
            key:'12',
            label:'December'
        }
    ]
}