import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { map, isEmpty } from 'lodash'
import { FaTimes } from "react-icons/fa"
import { createNotification } from "../../utils/helpers"
import { addEditSkillValidate } from "../../validations/user"
import { addSkill, removeSkill } from "../../actions/user"
import { getUser  } from "../../actions/auth"
import BoxCard from '../Common/BoxCard'

class Skill extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding:false,
            loading:false,
            saving:false,
            removing:false,
            edit:null,
            name:'',
            errors: {}
        };
    }

    componentDidMount() {
        
    }

    onChange(e) {
        this.setState({name:e.target.value})
    }

    onAdd(e) {
        this.setState({adding:!this.state.adding})
    }

    onEnter(e) {
        if( e.key == 'Enter' ) {
            this.onSubmit()
        }
    }

    handleClick(e) {
        this.onSubmit()
    }

    async onSubmit() {

        const userData = {
            name:this.state.name,
        };

        try {
            await addEditSkillValidate(userData)
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }

        this.setState({ errors: {}, loading:true, saving:true }, ()=>{
            let data = {
                jobseeker_skill_computer:this.state.name,
            }

            this.props.addSkill(data).then(res=>{
                this.setState({loading:false, saving:false, adding:false, name:''}, ()=>{
                    this.props.getUser()
                    this.makeEmptyFields()
                })
                
            }).catch(err=>{

            })
        })
    }

    onDelete(id, e) {
        this.setState({ errors: {}, loading:true, removing:true }, ()=>{
            this.props.removeSkill({
                jobseeker_skill_computer_id:id
            }).then(res=>{
                this.setState({loading:false, adding:false, removing:false }, ()=>{
                    this.props.getUser()
                    this.makeEmptyFields()
                })
            }).catch(err=>{

            })
        })
    }


    makeEmptyFields() {
        this.setState({
            edit:null,
            name:''
        })
    }

    render() {
      
        const { errors, cities, loadCities } = this.state

        const form = (state='new') => (<li style={{zIndex:100}} className="shadow mb-4 list-group-item bg-light align-items-center">
                <div className="mb-3 p-2">
                    <div className="form-group row">
                        <div className="col-12">
                            <label htmlFor="master">Insert your great skill and type Enter in keyboard</label>
                            <input type="text" name="name" 
                                className={classnames('form-control input-lg', {
                                    'is-invalid': errors.name
                                })}
                                value={this.state.name}
                                onChange={this.onChange.bind(this)} 
                                onKeyPress={this.onEnter.bind(this)}
                                placeholder={"E.g. Programming"}
                                disabled={this.state.saving}
                                id="master" />

                            {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                        </div>
                    </div>
                    {this.state.name !== '' && <div className="form-group row mt-3">
                        <div className="col-8">
                            <button type={'button'} disabled={this.state.saving} className="btn btn-danger" onClick={this.handleClick.bind(this)}>
                                {this.state.saving ? 'Adding...' : 'Add New'}
                            </button>
                        </div>
                    </div>}
                </div>
            </li>)

        return (
        <BoxCard title={'Skill'} width="12" withAction={true} stateAction={this.state.adding} onAction={this.onAdd.bind(this)}>
            <div className="ml-0">
                {this.state.adding && form()}
                {this.props.list && this.props.list.map(item => (
                    <React.Fragment key={item.jobseeker_skill_computer_id}>
                        <span key={item.jobseeker_skill_computer_id} style={{fontSize:20, paddingRight:'1.8rem', background:"#ddd",position:"relative"}} className="mb-2 mr-2 pl-4 py-2 badge text-dark badge-pill badge-secondary">{item.jobseeker_skill_computer} 
                            <span onClick={this.onDelete.bind(this, item.jobseeker_skill_computer_id)} title="Remove this skill" style={{position:"absolute",right:12, top:6, cursor:"pointer"}}className="text-dark">
                                <FaTimes size={12} color={"#777"} />
                            </span>
                        </span>
                    </React.Fragment>
                ))}
                {!this.props.list || isEmpty(this.props.list) && <li className="list-group-item d-flex justify-content-center align-items-center">
                        <p className="" style={{padding:'30px 0px'}}>
                            No list. Create one.
                        </p>
                </li> }
            </div>
        </BoxCard>)

    }

}


Skill.propTypes = {
    errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors
});


export default connect(mapStateToProps, { addSkill, removeSkill, getUser })(Skill);