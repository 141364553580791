import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import UserContext from "../../context/CurrentUserContext"
import { getUser } from '../../actions/auth';
import UserLayout from "../UserLayout"
import SEO from "../seo"
import Chatroom from "../User/Chatroom"
import SidebarMenu from "../sidebarMenu"

class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            errors: {}
        };
    }

    componentDidMount() {
        this.props.getUser()
    }

    render() {
        const { errors } = this.state;

        
        const isPremium = () => {
            return ( this.props.profile && this.props.profile.subscription && 
            _.has(this.props.profile.subscription, "status") && 
            this.props.profile.subscription.status == 'ok' ) ? true : false;
          }
  
        if( this.props.profile ) {
            return (
                <UserLayout page="user">
                    <SEO title="Message Box" />
                    <UserContext.Consumer>
                        {cuser => (<div className="container" id="messageBox">
                            <div className="align-items-center justify-content-center w-auto-xs bg-white">
                                <div className="row">
                                    <div className="col-10">
                                    
                                        <Chatroom profile={this.props.profile} user={this.props.auth.user} location={this.props.location} /> 
                                    </div>
                                    <div className="col-2 justify-content-right">
                                        <SidebarMenu message={this.props.profile.last_message} active="message" />
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </UserContext.Consumer>
                </UserLayout>
            )
        }

        return null
    }
}

Message.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile,
});

export default connect(mapStateToProps, { getUser })(Message);
