import React from 'react'
import { Router, Location, createHistory, createMemorySource } from '@reach/router'
// Global Components
import PrivateRoute from '../components/PrivateRoute'
import LoadingBar from 'react-redux-loading-bar'
import { NotificationContainer } from 'react-notifications'
import UserLayout from "../components/UserLayout"

// Auth Component
import Register from "../components/Auth/Register"
import Login from '../components/Auth/Login'
import Dashboard from "../components/UserApp/Dashboard"
import Application from "../components/UserApp/Application"
import Upgrade from "../components/UserApp/UpgradeMember"
import Logout from '../components/UserApp/Logout'
import Payment from '../components/UserApp/Payment'
import Cart from '../components/UserApp/Cart'
import Thank from "../components/UserApp/Thank"
import History from "../components/UserApp/History"
import Message from "../components/UserApp/Message"

const UserIndex = () => {
    return (
        <Location>
        {({ location }) => (
            <div className="body">
                <NotificationContainer />
                <LoadingBar scope="sectionBar" />
                <Router location={location}>
                    <PrivateRoute path="/user/dashboard" component={Dashboard} />
                    <PrivateRoute path="/user/logout" component={Logout} />
                    <PrivateRoute path="/user/application" component={Application} />
                    <PrivateRoute path="/user/upgrade" component={Upgrade} />
                    <PrivateRoute path="/user/payment" component={Payment} />
                    <PrivateRoute path="/user/cart" component={Cart} />
                    <PrivateRoute path="/user/thankyou" component={Thank} />
                    <PrivateRoute path="/user/history" component={History} />
                    <PrivateRoute path="/user/message" component={Message} />
                </Router>
            </div>
        )}
        </Location>
    );
}

export default UserIndex