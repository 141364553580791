import _ from 'lodash'
import { rest } from '../utils/rest'
import {
    GET_ERRORS,
    CREATE_PAYMENT,
    SUCCESS_PAYMENT,
    GET_SUBSCRIPTION,
} from './types';


export const createPayment = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('payment', args)
           .then(res=>{
               dispatch({
                   type:CREATE_PAYMENT,
                   payload:res.data
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err.response)
           })
   })
}

export const successPayment = (args) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .post('payment/success', args)
           .then(res=>{
               dispatch({
                   type:SUCCESS_PAYMENT,
                   payload:res.data
               })
               resolve(res.data)
           })
           .catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err.response)
           })
   })
}


export const getSubscription = (order_no=null) => dispatch => {
    return new Promise((resolve, reject) => {
       rest()
           .get('subscription', {
                params:{
                    order_no:order_no
                }
           }).then(res=>{
                dispatch({
                    type:GET_SUBSCRIPTION,
                    payload:res.data
                })

                resolve(res.data)

            }).catch(err=>{
               dispatch({
                   type: GET_ERRORS,
                   payload: err.response ? err.response : err
               })
               reject(err.response)
           })
   })
}