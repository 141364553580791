import React, { Component } from "react"
import { FaMicrosoft, FaCommentAlt, FaFileAlt, FaMoneyBillAlt } from "react-icons/fa"
import BoxCard from "./Common/BoxCard"
import classnames from 'classnames'
import { isEmpty } from "lodash"
import { Link, navigate } from "gatsby"
import { useMediaQuery } from 'react-responsive'

export default ({active, message=null}) => {
    const isTabletOrMobile = useMediaQuery({ maxWidth: 576 })
    const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 })
    const lastMessage = (message && ! isEmpty(message) ) ? message[0] : false;
    return (<BoxCard bgcolor="bg-light" padding="0" width="6" styles={{
        textAlign:"center", 
        color:'#333', 
        marginLeft:(isTabletOrMobile ? 12 : 24),  
        height:400
    }}>
        <Link to={'/user/dashboard'} className={classnames('d-block border-bottom', {
            'p-4':isDesktopOrLaptop,
            'px-2 py-4':isTabletOrMobile
        })}>
            <FaMicrosoft className={classnames('', {
                'text-danger': active == 'dashboard',
                'text-secondary': (active != 'dashboard')
            })} size={30} />
        </Link> 
        <Link to={'/user/application'} className={classnames('d-block border-bottom', {
            'p-4':isDesktopOrLaptop,
            'px-2 py-4':isTabletOrMobile
        })}>
            <FaFileAlt className={classnames('', {
                'text-danger': active == 'application',
                'text-secondary': active != 'application'
            })} size={30} />
        </Link> 
        <Link to={'/user/message'} className={classnames('d-block border-bottom', {
            'p-4':isDesktopOrLaptop,
            'px-2 py-4':isTabletOrMobile
        })}>
            {lastMessage && lastMessage.user_id == null && <span className="badge badge-pill badge-danger">new</span>}
            <FaCommentAlt className={classnames('', {
                'text-danger': active == 'message',
                'text-secondary': active != 'message'
            })} size={30} />
        </Link> 
        <Link to={'/user/history'} className={classnames('d-block border-bottom', {
            'p-4':isDesktopOrLaptop,
            'px-2 py-4':isTabletOrMobile
        })}>
            <FaMoneyBillAlt className={classnames('', {
                'text-danger': active == 'history',
                'text-secondary':active != 'history'
            })} size={30} />
        </Link> 
    </BoxCard> )
}