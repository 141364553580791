import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link, navigate } from "gatsby"
import { connect } from 'react-redux'
import { getUser } from '../../actions/auth';
import { createNotification, currency } from '../../utils/helpers'
import { createPayment, successPayment } from "../../actions/payment"
import UserContext from "../../context/CurrentUserContext"
import UserLayout from "../UserLayout"
import SEO from "../seo"
import BoxCard from "../Common/BoxCard"
import moment from "moment"

class Payment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            agree:false,
            scriptLoaded:false,
            token:'',
            errors: {}
        }
        this.days = 91;
        this.extra = 61;
        this.startDate = moment().add(1, 'days')
        this.endDate = moment().add( (this.days+this.extra), "days")
    }

    onSubmit(e) {

        const startDate = this.startDate.format("YYYY-MM-DD")
        const endDate = this.endDate.format('YYYY-MM-DD')

        const userData = {
            ...this.props.location.state,
            start_date:startDate,
            expiry_date:endDate
        }

        this.setState({
            loading:true
        })
        
        this.props.createPayment(userData)
            .then(res=>{
                this.setState({
                    scriptLoaded:true,
                })

                const react = this

                window.snap.pay(res.token, {
                    // Optional
                    onSuccess: function(result){
                        console.log(result)
                        react.props.successPayment({
                            result:result,
                            start_date:startDate,
                            expiry_date:endDate
                        }).then(res=>{
                            const data = res.data
                            react.setState({
                                loading:false,
                                agree:false
                            })
                            navigate('/user/thankyou?order_id=' + data.trx_id)
                        })
                    },
                    // Optional
                    onPending: function(result){
                        console.log(result)
                        react.props.successPayment({
                            result:result,
                            start_date:startDate,
                            expiry_date:endDate
                        }).then(res=>{
                            const data = res.data
                            react.setState({
                                loading:false,
                                agree:false
                            })
                            navigate('/user/thankyou?order_id=' + data.trx_id)
                        })
                    },
                    // Optional
                    onError: function(result){
                        console.log(result)
                        react.setState({
                            loading:false,
                            agree:false
                        })
                    },

                    onClose: function(){
                        createNotification('warning', 'Session closed! Please try again')
                        navigate('/user/cart')
                    }
                });

            }).catch(err=>{
                this.setState({
                    scriptLoaded:false,
                    loading:false,
                    agree:false
                })
            })  
    }

    componentDidMount() {
        if( _.isEmpty(this.props.location.state) ) {
            createNotification('warning', 'Session expired! Please try again')
            navigate('/user/cart')
        }

        this.props.getUser()
    }

    render() {
        const { errors } = this.state;
        const { profile } = this.props
        return (
            <UserLayout page="user">
                <SEO title="Payment" />
                <UserContext.Consumer>
                    {cuser => (<div className="container" id="upgradePayment">
                        <BoxCard bgcolor="bg-light" styles={{color:'#333'}}>
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <h5 className="mt-5 mb-4">Refund Policy</h5>
                                    <p>
                                    Thanks for subscription Membership Premium at Jobsmigas.com. 
                                    If you are not entirely satisfied with your purchase, we&#39;re here to help.
                                    </p>

                                    <p> <strong>Refunds</strong> </p>
                                    <p> After you have declared successful joining the premium membership program, We will not refund your money. </p>

                                    <p>
                                    You will get a notification to your email regarding activation of Premium membership							
                                    </p>

                                    <p>
                                        <strong>Terms Condition to Refund</strong>
                                    </p>
                                    <p>
                                    If you do not receive an email notification explaining that you have successfully become a premium membership within three days of you making payment, we will definitely refund your payment.
                                    </p>
                                    <p>
                                    If you receive a refund, the costs incurred because of a bank admin will be deducted from your refund.							
                                    </p>
                                    <p>
                                        <strong>Contact Us</strong>
                                    </p>
                                    <p> If you have any questions please contact us <a className="text-muted" href="mailto:support@jobsmigas.com">support@jobsmigas.com</a>
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-3">
                                <div className="col-12 border-top">
                                    <div className="custom-control mt-3 custom-checkbox">
                                        <input type="checkbox" onClick={()=>this.setState({
                                            agree:!this.state.agree
                                        })} id={`customBoxAgree`} name={'agree'} value={this.state.agree} className="custom-control-input" />
                                        <label className="mb-2 custom-control-label" htmlFor={`customBoxAgree`}> <strong> I Agree with Refund Policy </strong></label> 
                                        <div className="row">
                                            <div className="col-4">
                                                Subscription You Pay
                                            </div>
                                            <div className="col-8">
                                            <strong>{profile ? currency(profile.upgrade_info.fee) : '-'}  for 3 months  + <span>{`Bonus free 2 months membership.`}</span> </strong> ( {this.startDate.format("DD MMM YYYY")} s.d. {this.endDate.format("DD MMM YYYY")} )
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                    
                            {profile && <div className="row mt-3 justify-content-center text-center">
                                <div className="col-12 border-top">
                                    <button type="button" disabled={(!this.state.agree && !this.state.loading) || (this.state.agree && this.state.loading)} onClick={this.onSubmit.bind(this)} className="btn mt-4 mb-2 btn-danger btn-lg">{(this.state.agree && this.state.loading) ? 'Loading...' : 'Continue'}</button> <br/> <br/>

                                    <Link className="btn btn-outline-secondary btn-lg" to={'/user/cart'}>Back</Link>
                                </div>
                            </div>}
                        </BoxCard>
                       
                    </div>)}
                </UserContext.Consumer>
            </UserLayout>
        )
    }
}

Payment.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    applications: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile,
    applications: state.user.applications
});

export default connect(mapStateToProps, { createPayment, successPayment, getUser })(Payment);
