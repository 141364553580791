import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { forEach, map, isEmpty, truncate } from 'lodash'
import { FaUserCircle, FaEdit, FaTimes, FaUpload } from "react-icons/fa"
import { makeArrayKeyLabel, years, createNotification, diffYears, displayAvatar, checkEmptyBiodata, checkEmptyBiodataFields } from "../../utils/helpers"
import { restDropzone } from "../../utils/rest"
import { updateBiodata } from "../../validations/user"
import { updateBasicInfo, ppUpladed } from "../../actions/user"
import { getCities } from "../../actions/category"
import { getUser, setUserToStorage } from "../../actions/auth"
import lang from "../../langs"
import BoxCard from '../Common/BoxCard'
import RadioBox from "../Common/RadioBox"
import ChangePassword from "./ChangePassword"
import { dateNumber } from "../../utils/entries"
import Dropzone from "react-dropzone-uploader"
import Label from "../Common/Label"
import moment from "moment"
import { getDroppedOrSelectedFiles } from 'html5-file-selector'
import ResumeUpload from "../Upload/ResumeUpload"
import loadImage from "blueimp-load-image"

class Biodata extends Component {
    constructor(props) {
        super(props);
        const { 
            title,
            about,
            email,
            day,
            month,
            year,
            jobseekers_firstname, 
            jobseekers_lastname, 
            jobseekers_phone, 
            jobseekers_address, 
            jobseekers_image,
            jobseekers_date,
            jobseekers_religion,
            jobseekers_provinsi,
            jobseekers_city,
            jobseekers_marital,
            jobseekers_gender,
            jobseekers_zipcode
        } = props.profile

        this.mediaQuery = {
            desktop: 1200,
            tablet: 768,
            phone: 576,
        };

        this.state = {
            windowWidth:null,
            loading:false,
            saving:false,
            removing:false,
            edit:false,
            pp_image:jobseekers_image,
            new:{
                first_name:jobseekers_firstname,
                last_name:jobseekers_lastname,
                title:title,
                about:about,
                email:email,
                phone:jobseekers_phone,
                date:day,
                month:month,
                year:year,
                religion:jobseekers_religion == null ? '' : jobseekers_religion,
                province:jobseekers_provinsi == null ? '' : jobseekers_provinsi,
                city:jobseekers_city == null ? '' : jobseekers_city,
                address:jobseekers_address,
                zip_code:jobseekers_zipcode == null ? '' : jobseekers_zipcode,
                gender:jobseekers_gender,
                marital:jobseekers_marital == null ? '' : jobseekers_marital,
            },
            age:'',
            loadCities:false,
            cities:null,
            current_work:false,
            changePass:false,
            errors: {}
        };
    }

    componentDidMount() {
        const profile = this.props.profile
        if( checkEmptyBiodata(profile) == false ) {
            this.setState({
                edit:true
            })
        }

        this.setState({windowWidth: document.body.clientWidth})
        
    }
    componentDidUpdate(nextProps, prevState) {
        console.log(nextProps)
    }

    onChange(e) {
        this.setState({new:{
            ...this.state.new,
            [e.target.name]:e.target.value
        }})
    }

    onAdd(e) {
        this.setState({adding:!this.state.adding})
    }

    onProvinceSelect(e) {
        const code = e.target.value
        this.setState({loadCities:true, new:{
            ...this.state.new,
            province:code
        }}, ()=>{
            this.props.getCities(code)
                .then(res=>{
                    this.setState({
                        loadCities:false,
                        cities:res
                    })
                })
        })
        
    }

    onEdit(e) {
        const { 
            title,
            about,
            email,
            day,
            month,
            year,
            jobseekers_firstname, 
            jobseekers_lastname, 
            jobseekers_phone, 
            jobseekers_address, 
            jobseekers_image,
            jobseekers_date,
            jobseekers_religion,
            jobseekers_provinsi,
            jobseekers_city,
            jobseekers_marital,
            jobseekers_gender,
            jobseekers_zipcode
        } = this.props.profile

        this.setState({
            edit:true,
            pp_image:jobseekers_image,
            age:diffYears(`${year}-${month}-${day}`),
            new:{
                first_name:jobseekers_firstname,
                last_name:jobseekers_lastname,
                title:title,
                about:about == null ? '' : about,
                email:email,
                phone:jobseekers_phone == null ? '' : jobseekers_phone,
                date:day,
                month:month,
                year:year,
                religion:jobseekers_religion == null ? '' : jobseekers_religion,
                province:jobseekers_provinsi == null ? '' : jobseekers_provinsi,
                city:jobseekers_city == null ? '' : jobseekers_city,
                address:jobseekers_address == null ? '' : jobseekers_address,
                zip_code:jobseekers_zipcode == null ? '' : jobseekers_zipcode,
                gender:jobseekers_gender == null ? '' : jobseekers_gender,
                marital:jobseekers_marital == null ? '' : String(jobseekers_marital)
            }
        })

        if( jobseekers_provinsi ) {
            this.props.getCities(jobseekers_provinsi)
                .then(res=>{
                    this.setState({
                        loadCities:false,
                        cities:res
                    })
                })
        }
        
    }

    onSubmit(e) {
        e.preventDefault();
        const userData = {
            ...this.state.new,
        };

        try {
            updateBiodata(userData)
                .then(res=>{
                    this.setState({ errors: {}, loading:true, saving:true }, ()=>{
                        const { date, month, year } = this.state.new
                        const dateOfBirth = `${year}-${month}-${date}`
                        let data = {
                            jobseekers_firstname:this.state.new.first_name,
                            jobseekers_lastname:this.state.new.last_name,
                            jobseekers_phone:this.state.new.phone,
                            jobseekers_religion:this.state.new.religion,
                            jobseekers_marital:this.state.new.marital,
                            jobseekers_gender:this.state.new.gender,
                            jobseekers_provinsi:this.state.new.province,
                            jobseekers_city:this.state.new.city,
                            jobseekers_address:this.state.new.address,
                            jobseekers_zipcode:this.state.new.zip_code,
                            title:this.state.new.title,
                            email:this.state.new.email,
                            about:this.state.new.about,
                            dateOfBirthday:dateOfBirth
                        }
            
                        this.props.updateBasicInfo(data).then(res=>{
                            this.props.getUser()
                            this.setState({loading:false, saving:false})
                        }).catch(err=>{
                            createNotification('error', 'Failed update biodata!')
                        })
                    })
                })
                .catch(err=>{
                    this.setState({ errors: err })
                    createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
                    return false;
                })
        } catch (err) {
            this.setState({ errors: err })
            createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
            return false;
        }
    }

    onChangePass(e) {
        this.setState({
            changePass:!this.state.changePass
        })
    }

    calcAge(date, month, year) {
        if( date && month && year ) {
            this.setState({
                age:diffYears(`${year}-${month}-${date}`)
            }) 
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { date, month, year } = prevState.new
        const st = this.state.new
        // only update chart if the data has changed
        if( date != st.date || month != st.month || year != st.year ) {
            this.calcAge(st.date, st.month, st.year)
        }
      }

    getUploadParams = ({ file, meta }) => {
        const body = new FormData
        body.append('picture', file)
        return restDropzone('me/update-file', body)
    }
    
    uploadChangeStatus = ({ meta, xhr, remove }, status) => {
        if (status === 'headers_received') {
            remove()
        } else if( status == 'exception_upload' ) {
            createNotification('error', 'No connection when upload. Please check your internet connection')
        } else if( status == 'error_upload' ) {
            createNotification('error', 'Failed upload!')
        } else if (status === 'preparing') {
            if( this.props.maxSize > meta.size ) {
                this.setState({
                    pp_image:null
                })
            }
            
        } else if (status === 'done') {
            const resp = xhr.response
            const response = JSON.parse(resp)
            if( response.error === false ) {
                createNotification('success', 'Your Profile Picture is uploaded')
                this.props.ppUpladed(response.user.jobseekers_image)
                this.setState({
                    pp_image:response.user.jobseekers_image
                })
            }         
        }
    }


    handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta))
        allFiles.forEach(f => f.remove())
    }

    getFilesFromEvent = e => {
        return new Promise(resolve => {
            getDroppedOrSelectedFiles(e).then(chosenFiles => {
                resolve(chosenFiles.map(f => f.fileObject))
            })
        })
    }
    
    render() {
      
        const { errors, cities, loadCities, pp_image } = this.state
        const { profile, maxSize, cuser } = this.props
        const { religions, maritals } = profile
        const pimage = this.props.ppImage ? this.props.ppImage : profile.jobseekers_image
        const name = profile.jobseekers_firstname + ' ' + (profile.jobseekers_lastname ? profile.jobseekers_lastname : '')
        const cv = this.props.resume ? this.props.resume : profile.jobseekers_resume

        
        const Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
            const text = files.length > 0 ? '' : 'Browse Picture file'
            return (
                <div className="input-upload">
                    <label className="shadow justify-content-center align-items-center" style={{ cursor: 'pointer', padding: 15, borderRadius: 30 }}>
                        <FaUpload size={20} />
                        <input
                            style={{ display: 'none' }}
                            name="picture"
                            type="file"
                            accept={accept}
                            onChange={e => {
                                this.getFilesFromEvent(e).then(chosenFiles => {
                                    onFiles(chosenFiles)
                                })
                            }}
                        />
                    </label>
                </div>
            )
        }

        const CustomDropzoneLayout = ({ previews, submitButton, input, files, dropzoneProps }) => {
            const { ref, className, style } = dropzoneProps
            return (
              <div ref={ref} className={className} style={style}>
                {previews}{pimage && <img style={{maxWidth:"100%"}} src={displayAvatar(pimage)} />}{input}
          ​    </div>
            )
        }
        
        const form = (state='new') => (<div style={{zIndex:100}} className="align-items-center">
                <div className="row mb-3">
                    <div className="col-12 alert alert-info">
                        Please Complete it your biodata, upload last profile picture and upload your last CV
                    </div>
                </div>
                <div className="row mb-3 p-2">
                    <div className="col-lg-4 col-12">
                        <div id="pp" className="text-center">
                            <Dropzone 
                                getUploadParams={this.getUploadParams}
                                onChangeStatus={this.uploadChangeStatus.bind(this)}
                                onSubmit={this.handleSubmit}
                                maxFiles={1}
                                onDrop={acceptFile =>{
                                    loadImage(acceptFile, function (img, data) {
                                        console.log('Original image head: ', data.imageHead)
                                        console.log('Exif data: ', data.exif) // requires exif extension
                                        console.log('IPTC data: ', data.iptc) // requires iptc extension
                                    }, {
                                        includeExifTags: {
                                          0x0112: true, // Orientation
                                          ifd1: {
                                            0x0201: true, // JPEGInterchangeFormat (Thumbnail data offset)
                                            0x0202: true // JPEGInterchangeFormatLength (Thumbnail data length)
                                          },
                                          0x8769: {
                                            // ExifIFDPointer
                                            0x9000: true // ExifVersion
                                          }
                                        }
                                    } )
                                }}
                                maxSizeBytes={maxSize}
                                accept="image/jpeg,image/png,image/gif"
                                InputComponent={Input}
                                LayoutComponent={CustomDropzoneLayout}
                                id={"pp"}
                                multiple={false}
                                canCancel={false}
                                styles={{
                                    dropzoneActive: { borderColor: 'green' }  
                                }}>
                            </Dropzone>
                            <small><em>Allow upload picture MAX. 1 Mb with <strong>MIN. Resolution 160px x 160px </strong></em></small>
                        </div>
                        <ResumeUpload cuser={cuser} profile={profile} file={cv} />
                    </div>

                    <div className="col-lg-8 col-12">
                        <div className="form-group row">
                            <div className="col-12 col-lg-3">
                                <label htmlFor="gender">Gender</label>
                            </div>
                            <div className="col-6 col-lg-3">
                                <RadioBox id={"male"} onClick={this.onChange.bind(this)} value="male" name="gender" label="Male" selected={this.state.new.gender} />
                            </div>

                            <div className="col-6 col-lg-3">
                                <RadioBox id={"female"} onClick={this.onChange.bind(this)} value="female" name="gender" label="Female" selected={this.state.new.gender} />
                            </div>
                            {errors.gender && <div className="col-12 mb-3">
                                <div className="text-danger">{errors.gender}</div>
                            </div>}
                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-lg-6">
                                <label htmlFor="school">First name</label>
                                <input type="text" name="first_name" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.first_name
                                    })}
                                    value={this.state.new.first_name}
                                    onChange={this.onChange.bind(this)} 
                                    id="school" />
                                {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                            </div>
                            <div className="col-12 col-lg-6">
                                <label htmlFor="school">Last name</label>
                                <input type="text" name="last_name" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.last_name
                                    })}
                                    value={this.state.new.last_name}
                                    onChange={this.onChange.bind(this)} 
                                    id="last_name" />
                                {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="school">Job Title</label>
                                <input type="text" name="title" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.title
                                    })}
                                    placeholder={"e.g. Welder, Fitter, Scaffolder..."}
                                    value={this.state.new.title}
                                    onChange={this.onChange.bind(this)} 
                                    id="title" />
                                {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12">
                                <label htmlFor="about">My Resume</label>
                                <textarea name="about" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.about
                                    })}
                                    rows={3}
                                    placeholder={"Please describe about your qualification, experience or may be work performance"}
                                    value={this.state.new.about}
                                    onChange={this.onChange.bind(this)} 
                                     aria-describedby="aboutHelpBloick"
                                    id="about" />
                                <small id="aboutHelpBloick" className="form-text text-muted">Please describe about your qualification, experience or may be work performance</small> <br /> 
                                {errors.about && <div className="invalid-feedback">{errors.about}</div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-lg-6">
                                <label htmlFor="about">Contact</label>
                                <input type="email" name="email" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.email
                                    })}
                                    placeholder={"Email Address"}
                                    value={this.state.new.email}
                                    onChange={this.onChange.bind(this)} 
                                    id="email" />
                                {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                            </div>
                           
                            <div className="col-12 col-lg-6">
                                <label htmlFor="phone">Mobile Number</label>
                                <input type="text" name="phone" 
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.phone
                                    })}
                                    value={this.state.new.phone}
                                    onChange={this.onChange.bind(this)} 
                                    id="phone" />
                                {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-lg-10">
                                <label htmlFor="">Birth of date</label>
                                <div className="row">
                                    <div className="col-12 col-lg-3">
                                        <select name="date" onChange={this.onChange.bind(this)} 
                                            id="date" 
                                            value={this.state.new.date}
                                            className={classnames('form-control input-lg', {
                                                'is-invalid': errors.date
                                            })}
                                        >
                                            <option disabled value="">--date--</option>
                                            {dateNumber() && dateNumber().length > 0  && dateNumber().map((i, d) => <option value={i}>{i}</option> )}
                                        </select>
                                        {errors.date && <div className="invalid-feedback">{errors.date}</div>}
                                    </div>

                                    <div className="col-12 col-lg-6">
                                        <select name="month" onChange={this.onChange.bind(this)} 
                                            id="month" 
                                            value={this.state.new.month}
                                            className={classnames('form-control input-lg', {
                                                'is-invalid': errors.month
                                            })}
                                        >
                                            <option disabled value="">--month--</option>
                                            {lang('month_names') && lang('month_names').map((i, d) => <option value={i.key}>{i.label}</option> )}
                                        </select>
                                        {errors.month && <div className="invalid-feedback">{errors.month}</div>}
                                    </div>

                                    <div className="3">
                                        <select name="year" 
                                        defaultValue={this.state.new.year}
                                        onChange={this.onChange.bind(this)} 
                                        id="year" 
                                        className={classnames('form-control input-lg', {
                                            'is-invalid': errors.year
                                        })}>
                                            <option disabled value="">--year--</option>
                                            {makeArrayKeyLabel(years()).map((it,i)=> <option value={it.label}>{it.label}</option>)}
                                        </select>
                                        {errors.year && <div className="invalid-feedback">{errors.year}</div>}
                                    </div>

                                </div>
                            </div>
                            
                            <div className="col-12 col-lg-2">
                                <label htmlFor="">Age</label>
                                <input type="text" readOnly={true} name="age" 
                                    className={classnames('form-control input-lg', {'is-invalid': errors.age})}
                                    value={this.state.age}
                                    id="age" />
                                {errors.age && <div className="invalid-feedback">{errors.age}</div>}
                            </div>

                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-lg-6">
                                <label htmlFor="religion">Religion</label>
                                <select name="religion" onChange={this.onChange.bind(this)} 
                                    id="religion" 
                                    value={this.state.new.religion}
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.religion
                                    })}
                                >
                                    <option disabled value="">--Choose Religion--</option>
                                    {religions.map((i, d) => <option value={i.id}>{i.nama}</option> )}
                                </select>
                                {errors.religion && <div className="invalid-feedback">{errors.religion}</div>}
                            </div>
                            <div className="col-12 col-lg-6">
                                <label htmlFor="marital">Marital Status</label>
                                <select name="marital" onChange={this.onChange.bind(this)} 
                                    id="marital" 
                                    value={this.state.new.marital}
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.marital
                                    })}
                                >
                                    <option disabled value="">--Choose marital--</option>
                                    {maritals.map((i, d) => <option value={i.id}>{i.nama}</option> )}
                                </select>
                                {errors.marital && <div className="invalid-feedback">{errors.marital}</div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-lg-6">
                                <label htmlFor="jobseekers_work_company">Province</label>
                                <select name="province" onChange={this.onProvinceSelect.bind(this)} 
                                    id="province" 
                                    value={this.state.new.province}
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.province
                                    })}
                                >
                                    <option disabled value="">--Choose province--</option>
                                    {this.props.provinces.map((i, d) => <option value={i.lokasi_kode}>{i.lokasi_nama}</option> )}
                                </select>
                                {errors.province && <div className="invalid-feedback">{errors.province}</div>}
                            </div>
                            <div className="col-12 col-lg-6">
                                <label htmlFor="cities">City</label>
                                <select name="city" onChange={this.onChange.bind(this)} 
                                    id="cities" 
                                    value={this.state.new.city}
                                    className={classnames('form-control input-lg', {
                                        'is-invalid': errors.city
                                    })}
                                >
                                    <option disabled value="">--Choose city--</option>
                                    {cities && cities.length > 0  && cities.map((i, d) => <option value={i.lokasi_kode}>{i.lokasi_nama}</option> )}
                                </select>
                                {loadCities && <div className="spinner-circle" style={{ width:'1rem', height:'1rem', position:"absolute", right:10, top: 20 }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>}
                                {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-12 col-lg-8">
                                <label htmlFor="address">Residential Address</label>
                                <textarea name="address" 
                                    onChange={this.onChange.bind(this)}
                                    placeholder={"Please enter residential address"}
                                     className={classnames('form-control input-lg', {
                                        'is-invalid': errors.address
                                    })} id="address" 
                                    value={this.state.new.address} cols="30" rows="4"></textarea>
                                {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                            </div>

                            <div className="col-12 col-lg-4">
                                <label htmlFor="zip_code">Zip Code</label>
                                <input type="text" maxLength={6} name="zip_code" 
                                    className={classnames('form-control input-lg')}
                                    value={this.state.new.zip_code}
                                    onChange={this.onChange.bind(this)} 
                                    id="zip_code" />
                            </div>
                        </div>
                    
                        <div className="form-group row mt-2">
                            <div className="col-6 col-lg-3">
                                <button type="button" disabled={this.state.saving ? true : false} onClick={this.onSubmit.bind(this)} name="doSubmit" id="submitExp" className={classnames("btn btn-block btn-lg btn-danger", {
                                    "d-none":this.state.removing == true
                                })}>{this.state.loading ? 'Saving...' : 'Save'}&nbsp;</button>
                            </div>
                           {checkEmptyBiodataFields(profile) && pimage && cv && <div className="col-6 col-lg-3">
                                <button type="button" onClick={()=>this.setState({edit:false}) } name="doCancel" id="cancelExp" 
                                className={classnames("btn btn-lg btn-block btn-outline-secondary", {
                                    "d-none":this.state.saving === true
                                })}>{'Cancel'}&nbsp;</button>
                            </div>}
                            
                        </div>
                    
                    </div>
                </div>
            </div>)

        return (
            <BoxCard width="12" padding={this.state.windowWidth < this.mediaQuery.phone ? 3 : 5} title="Basic Info">
                {this.state.edit && form()}
                {this.state.edit === false && <div className="row">
                    <div className="col-lg-4 col-12">
                        {pimage ? <img style={{maxWidth:"100%"}} src={displayAvatar(pimage)} alt={profile.jobseekers_firstname} /> : <FaUserCircle size={240} color={"#ccc"} />}

                        {cv ? <h5 className="my-2 mt-4">
                            My Latest CV : <br/>
                            <div className="mt-2 badge badge-pill badge-warning">{truncate(cv, {length:38})}</div>
                        </h5> : <h5 className="my-2"><span className="text-muted">No CV file attached. Please click edit button to upload your CV</span></h5>  }
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="mb-4 pb-4 border-bottom">
                            <h2><span className="badge badge-pill badge-danger">Hello</span></h2>
                            <h1 className="display-4 mb-0"> I'm <span className="font-weight-bold">{name}</span></h1>
                        </div>

                        <Label label="title" value={profile.title} />
                        <Label label="age" value={diffYears(profile.jobseekers_date)} />
                        <Label label="address" value={profile.jobseekers_address} />
                        <Label label="phone" value={profile.jobseekers_phone} />
                        <Label label="email" value={profile.email} />
                        <Label label="resume" value={profile.about} />
                        <div className="mb-4 pb-4 border-bottom"></div>
                        <div className="row">
                            <div className="col-3">
                                <button type="button" onClick={this.onEdit.bind(this)} className={"btn btn-outline-danger"}>Edit</button>
                            </div>
                            <div className="col-5 justify-content-end ml-auto align-items-end">
                                <button type="button" onClick={this.onChangePass.bind(this)} className={classnames("btn", {
                                    "btn-danger":!this.state.changePass,
                                    "btn-secondary":this.state.changePass
                                })}>
                                {this.state.changePass ? 'Cancel Update Password' : 'Change Password'}</button>
                            </div>
                        </div>
                       
                    </div>
                    {this.state.changePass && <div className="col-12">
                        <ChangePassword />
                    </div>}
                </div>}

            </BoxCard>)

    }

}

Biodata.defaultProps = {
    maxSize:1048576,
    maxImageWidth:160,
    maxImageHeight:160
}

Biodata.propTypes = {
    maxSize: PropTypes.number,
    maxImageWidth: PropTypes.number,
    maxImageHeight: PropTypes.number,
    errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors,
    ppImage: state.user.pp,
    resume: state.user.resume
});


export default connect(mapStateToProps, { updateBasicInfo, getUser, getCities, ppUpladed })(Biodata);