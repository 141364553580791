import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Link, navigate } from "gatsby"
import { connect } from 'react-redux'
import { getUser } from '../../actions/auth';
import { getApplications } from '../../actions/user';
import { currency, isPremiumProcess, checkEmptyBiodata } from '../../utils/helpers'
import UserContext from "../../context/CurrentUserContext"
import UserLayout from "../UserLayout"
import Layout from '../layout'
import { FaUserCircle, FaCheckCircle } from "react-icons/fa"
import SEO from "../seo"
import BoxCard from "../Common/BoxCard"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

class UpgradeMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            agree:false,
            errors: {}
        };
    }

    componentDidMount() {
        this.props.getUser()
    }

    onContinue(e) {
        e.preventDefault()
        if( checkEmptyBiodata(this.props.profile) == false ) {
            MySwal.fire('Unable to Continue', 'Please complete your biodata, profile picture and resume', 'warning')
            setTimeout(()=>{
                navigate('/user/dashboard')
              }, 4000)
        } else {
            navigate('/user/cart')
        }
    }

    render() {
        const { errors } = this.state;
        const { profile } = this.props
        return (
            <UserLayout page="user">
                <SEO title="Upgrade Membership" />
                <UserContext.Consumer>
                    {cuser => (<div className="container" id="upgradeMembership">
                        <BoxCard bgcolor="bg-light">
                            <div className="row border-top pt-4">
                                <div className="col-12 text-center mb-5">
                                    <h2 className="text-center mb-4"> Jobsmigas Premium </h2>
                                    <p className="mb-5 lead">
                                    Jobsmigas is helping job seeker to find jobs easier especially if you’re seeking jobs to website www.jobsmigas.com.
                                    </p>
                                    <p>
                                    Good news for you all jobs seekers! jobsmigas has came up with a solution to make finding jobs easily , we call it <strong>“JOBSMIGAS PREMIUM”</strong> it will give you 4 advantages compared to normal users.
                                    </p>
                                </div>
                                
                                <div className="col-lg-7 col-12">
                                    <h4 className="mb-3">Why you upgrade to JOBSMIGAS PREMIUM?</h4>
                                    <ul className="mt-4" style={{listStyle:"none", paddingLeft:0}}>
                                        <li className="mb-4">
                                            <div className="mb-2 row">
                                            <span className="col-auto"><FaCheckCircle className="text-success" size={22}/></span>
                                            <span className="col pl-0">
                                                <h5 className="text-success">Jobs Notification Priority</h5> <strong class="text-muted">This feature will give you a job notification priority to your email if there is a vacancy that suits with Your specialist. You will get the job vacancy exclusively faster than other jobseekers</strong> </span>
                                            </div>
                                        </li>
                                        <li className="mb-4">
                                            <div className="mb-2 row">
                                            <span className="col-auto"><FaCheckCircle className="text-success" size={22}/></span>
                                            <span className="col pl-0">
                                                <h5 className="text-success">News &amp; Info exclusive about recruitment, internship program, free training, project, education.</h5> <strong class="text-muted">Only you will get exclusively information from us about recruitment, internship programs, free training, projects, education.</strong> </span>
                                            </div>
                                        </li>
                                        <li className="mb-4">
                                            <div className="row">
                                                <span className="col-auto"><FaCheckCircle className="text-success" size={22}/> </span>
                                                <span className="col pl-0"> 
                                                <h5 className="text-success">Access communication to Recruiter with message box</h5>
                                                <strong class="text-muted">You could use comfortably and safely the communication features that strive for to facilitate and speed up the communication from recruiter to you directly in one platform, with a recorded and accountable communication security system and the information obtained We could guarantee that its from HR Company directly to premium members. </strong></span>
                                            </div>
                                        </li>

                                        <li className="mb-4">
                                            <div className=" row">
                                                <span className="col-auto"><FaCheckCircle className="text-success" size={22}/> </span>
                                                <span className="col pl-0"> 
                                                <h5 className="text-success">Result and feedback review from recruiter</h5> 
                                                <strong class="text-muted">The recruiter could directly give Your result through this feature without wasting your time to apply again. You just have to wait for further info from the HR Company.</strong></span>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="col-lg-5 col-12">
                                    <div className="card shadow-sm text-center p-3" style={{
                                        background:'#c7e6c7',
                                        borderWidth:3
                                    }}>
                                        <div className="mb-3"> <h4>Subscription Fee</h4> </div>
                                        <div> 
                                            <div className="d-flex justify-content-center">
                                                <div className="my-2" style={{
                                                    textAlign:"center",
                                                    justifyContent:"center",
                                                    justifyItems:"center",
                                                    alignContent:"center",
                                                    alignItems:"center",
                                                    height:130,
                                                    width:2,
                                                    borderLeftWidth:5,
                                                    borderLeftStyle:"solid",
                                                    borderLeftColor:"darkcyan"
                                                }}>
                                                </div>
                                            </div>
                                        </div>
                                        <h2 className="mt-2">
                                        <span className="display-3">{ profile ? currency(profile.upgrade_info.fee) : '-' }</span> <br />/ 3 months + <span>{`Bonus free 2 months membership.`}</span>
                                        </h2>
                                        <div> 
                                            <div className="d-flex justify-content-center">
                                                <div className="my-2" style={{
                                                    textAlign:"center",
                                                    justifyContent:"center",
                                                    justifyItems:"center",
                                                    alignContent:"center",
                                                    alignItems:"center",
                                                    height:130,
                                                    width:2,
                                                    borderLeftWidth:5,
                                                    borderLeftStyle:"solid",
                                                    borderLeftColor:"darkcyan"
                                                }}>
                                                </div>
                                            </div>
                                            <Link className="btn mt-3 btn-outline-info btn-lg" to={'/user/dashboard'}>Later</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            { ( !isPremiumProcess(this.props.profile) ) && <div className="row mt-3 justify-content-center text-center">
                                <div className="col-12 border-top">
                                    {profile && <a href="#" onClick={this.onContinue.bind(this)} className="btn mt-4 mb-2 btn-danger btn-lg">Upgrade to Jobsmigas Premium</a>}
                                </div>
                            </div>}
                        </BoxCard>
                       
                    </div>)}
                </UserContext.Consumer>
            </UserLayout>
        )
    }
}

UpgradeMember.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile
});

export default connect(mapStateToProps, { getUser })(UpgradeMember);
