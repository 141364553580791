import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getSubscriptions } from '../../actions/user';
import { getUser } from '../../actions/auth';
import { currency } from '../../utils/helpers'
import UserContext from "../../context/CurrentUserContext"
import UserLayout from "../UserLayout"
import SEO from "../seo"
import ItemListLoader from "../Loader/ItemListLoader"
import BoxCard from "../Common/BoxCard"
import moment from "moment"
import SidebarMenu from "../sidebarMenu"

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            limit:20,
            offset:0,
            errors: {}
        };
    }

    componentDidMount() {
       this.props.getSubscriptions({
        limit:this.state.limit,
        offset:this.state.offset
       })
       this.props.getUser();
    }

    render() {
        const { errors } = this.state;
        const list = this.props.subscriptions

        if ( (!list || !_.has(list, 'data')) || !this.props.profile ) {
            return (<UserLayout>
                <BoxCard bgcolor="bg-light" styles={{color:'#333'}} title="My Subscription History">
                    <ItemListLoader />
                    <ItemListLoader />
                    <ItemListLoader />
                    <ItemListLoader />
                </BoxCard>
               
            </UserLayout>)
        }
        
        return (
            <UserLayout page="user">
                <SEO title="User History" />
                <UserContext.Consumer>
                    {cuser => (<div className="container" id="bodyApplications">
                        <div className="align-items-center justify-content-center w-auto-xs bg-white">
                            <div className="row">
                                <div className="col-10">
                                    <BoxCard title={'My History Subscription'} width="12" withAction={false}>
                                        <ul class="list-group border-none ml-0">
                                            {list.data && list.data.map(item => (
                                                <>
                                                    <li className="list-group-item align-items-center">
                                                        <h5 className="mb-1">{item.type}</h5>
                                                        <p className="lead font-weight-bold text-danger mb-1">{currency(item.total)}</p>
                                                        <div className="row">
                                                            <div className="col">
                                                                {<p className="lead mb-1">Mulai tanggal {moment(item.start_date).format("DD MMM YYYY")} s.d. {moment(item.expiry_date).format("DD MMM YYYY")} </p>}
                                                                {<p className="mb-1">{item.remark}</p>}
                                                            
                                                            </div>
                                                            <div className="col">
                                                                <p className="text-right" style={{flexGrow:2}}>
                                                                    <>
                                                                    {item.status == 'ok' && <span className="badge badge-success">{'OK'}</span>}
                                                                    {item.status == 'pending' && <span className="badge badge-secondary">{'Pending'}</span>}
                                                                    {item.status == 'waiting' && <span className="badge badge-secondary">{'Waiting'}</span>}
                                                                    {item.status == 'canceled' && <span className="badge badge-danger">{'Failed'}</span>}
                                                                    {item.status == 'expired' && <span className="badge badge-danger">{'Expired'}</span>}
                                                                    {item.status == 'need_approval' && <span className="badge badge-info">{'Waiting Approval'}</span>}
                                                                    </>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        
                                                    </li>
                                                </>
                                            ))}
                                            {(!list && _.isEmpty(list.data)) && <li className="list-group-item d-flex justify-content-center align-items-center">
                                                    <p className="" style={{padding:'30px 0px'}}>
                                                        No history found
                                                    </p>
                                            </li>}
                                        </ul>
                                    </BoxCard>
                                </div>
                                <div className="col-2 justify-content-right">
                                    <SidebarMenu message={this.props.profile.last_message} active="history" />
                                </div>
                            </div>
                            
                        </div>
                    </div>)}
                </UserContext.Consumer>
            </UserLayout>
        )
    }
}

History.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    subscriptions: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile,
    subscriptions: state.user.subcriptions
});

export default connect(mapStateToProps, { getSubscriptions, getUser })(History);
