import React from 'react'
import ContentLoader from 'react-content-loader'

const ChatLoader = props => {
  return (

    <ContentLoader
      height={300}
      width={500}
      speed={2}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="490" height="20" />
      <rect x="0" y="30" rx="0" ry="0" width="150" height="270" />
      <rect x="160" y="30" rx="0" ry="0" width="330" height="220" />
      <rect x="160" y="260" rx="0" ry="0" width="330" height="40" />
    </ContentLoader>
  )
}

export default ChatLoader   
