import { Link as Glink } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import { connect } from 'react-redux';
import classnames from "classnames"
import { displayAvatar } from "../utils/helpers"
import UserContext from "../context/CurrentUserContext"
import Logo from "./Common/Logo"
import { FaUserCircle, FaTimesCircle } from "react-icons/fa"
import { isPremiumProcess } from "../utils/helpers"
import { isNil, has } from "lodash"
import 'react-notifications/lib/notifications.css'
import "../sass/main.scss"

class PrivateHeader extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      dropdown:false
    }
  }

  onToggleShow(e) {
    e.preventDefault()
    this.setState({dropdown:!this.state.dropdown})
  }

  onClose(e) {
    e.preventDefault()
    this.setState({dropdown:false})
  }
  
  render() {
    const { siteTitle, auth, profile } = this.props
    return (
      <UserContext.Consumer>
        {cuser => (<header className="bg-dark" 
          style={{
            marginBottom: `1.45rem`,
          }}
        >
          
          <div
            id="header"
            className="container user-dashboard"
            style={{
              padding: `1.45rem 15px`,
            }}
          >
            <div className="row">
              <div className="left col-lg-4 col-12">
                <h1 style={{ margin: 0 }}>
                  <Glink
                    to="/"
                  >
                    <Logo alt={siteTitle} />
                  </Glink>
                </h1>
              </div>
              <div className="right col-lg-8 col-12">
                <nav className="navbar navbar-expand-lg navbar-light text-white">
                <div className="navbar-collapse" id="navbarNav" style={{
                  fontFamily:"Roboto"
                }}>
                  <ul className="navbar-nav d-flex" style={{width:"100%", fontSize:18}}>
                    <li className="nav-item active mb-0">
                      <Glink className="nav-link  text-white" to={'/'}>Home <span className="sr-only">(current)</span></Glink>
                    </li>
                    <li className="nav-item mb-0">
                      <Glink className="nav-link  text-white" to={'/job'}>Search Jobs</Glink>
                    </li>
                    <li className="nav-item mb-0">
                      <Glink className="nav-link  text-white" to={'/company'}>Search Company</Glink>
                    </li>

                    <li className="nav-item mb-0">
                      <Glink className="nav-link  text-white" to={'/news'}>News</Glink>
                    </li>

                    <li className="nav-item nav-right mb-0 text-right col align-self-end">
                      {auth.user && <div className={classnames("dropdown", {
                          'show':this.state.dropdown
                        })} style={{
                          display:"inline-block"
                        }}>
                        <a className="btn btn-secondary dropdown-toggle" href="#" onClick={this.onToggleShow.bind(this)} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.dropdown ? 'true' : 'false'}>
                          {auth.user && has(auth.user, 'jobseekers_image') && auth.user.jobseekers_image && <img src={displayAvatar(auth.user.jobseekers_image)} className="rounded" width={20} />} {auth.user ? auth.user.jobseekers_firstname : 'Guest'}
                        </a>

                        <div className={classnames("dropdown-menu", {
                          'show':this.state.dropdown
                        })} aria-labelledby="dropdownMenuLink" style={{
                          position:"absolute",
                          willChange:"transform",
                          top:0,
                          width:200,
                          right:0,
                          transform:"translate3d(0px, 38px, 0px)",
                          WebkitTransform:"translate3d(0px, 38px, 0px)"
                        }}>
                          <div style={{position:"relative"}}>
                            <FaTimesCircle style={{
                                  position:"absolute",
                                  right:10,
                                  top:6,
                                  cursor:"pointer"
                                }} size={18} onClick={this.onClose.bind(this)} color={"#aaa"} />
                            <Glink className="dropdown-item" to="/user/dashboard">My Profile CV</Glink>
                            <Glink className="dropdown-item" to="/user/application">Application</Glink>
                            <div className="dropdown-divider"></div>
                            <Glink className="dropdown-item" to="/user/message">Message Box {profile && profile.last_message && profile.last_message.length > 0 && profile.last_message[0].user_id == null && <span className="badge badge-pill badge-danger">New</span>}</Glink>
                            <div className="dropdown-divider"></div>
                            {!isPremiumProcess(this.props.profile) && 
                              <>
                                <Glink className="dropdown-item" to="/user/upgrade">Upgrade <span className="badge badge-pill badge-success">New</span></Glink>
                                <div className="dropdown-divider"></div>
                              </>}
                            <Glink className="dropdown-item" to="/user/logout">Logout</Glink>
                          </div>
                        </div>
                      </div>}
                    </li>
                  </ul>
                </div>
                </nav>
              </div>
            </div>
            
          </div>
        </header>)}
      </UserContext.Consumer>
    )
  }
  
}


PrivateHeader.propTypes = {
  siteTitle: PropTypes.string,
}

PrivateHeader.defaultProps = {
  siteTitle: ``,
}

const mapStateToProps = state => ({
  auth:state.auth,
  profile:state.user.profile
})

export default connect(mapStateToProps, {})(PrivateHeader)
