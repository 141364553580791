import React from "react"
import { connect } from "react-redux"
import { logoutUser } from "../../actions/auth"
import { Redirect } from "@reach/router"
import { Link, navigate } from "gatsby"
import Spinner from "../../components/Spinner"

class Logout extends React.Component {

    constructor(props) {
        super(props)  
    }
    
    /**
     * Component will mout
     */
    componentDidMount() {
        this.props.logoutUser()
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.auth.isAuthenticated) {
            navigate('/user/login');
        }
    }

    render() {
        return <div className="d-flex" style={{
            justifyContent:"center",
            alignContent:"center",
            alignItems:"center",
            textAlign:"center",
            flexGrow:3,
            height:400,
            flexDirection:"column"
        }}>
            <Spinner />
            <h2>Please wait redirect to Login screen</h2>
        </div>
    }

}

const mapStateToProps = state => ({
    auth:state.auth
});

export default connect(mapStateToProps, { logoutUser })(Logout)