import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link, navigate } from "gatsby"
import _ from 'lodash'
import { connect } from 'react-redux'
import { getUser, getCollectionOfUser } from '../../actions/auth';
import { getApplications } from '../../actions/user';
import { createNotification, pageTitle, displayAvatar, diffYears, calculateProgressResume, checkEmptyBiodata, checkEmptyBiodataFields } from '../../utils/helpers'
import UserContext from "../../context/CurrentUserContext"
import UserLayout from "../UserLayout"
import JobInteresting from "../User/JobInteresting"
import WorkExperience from "..//User/WorkExperience"
import Education from "../User/Education"
import Skill from "../User/Skill"
import Language from "../User/Language"
import BoxCard from "../Common/BoxCard"
import Label from "../Common/Label"
import Biodata from "../User/Biodata"
import SEO from "../seo"
import ProfileLoader from "../Loader/ProfileLoader"
import ItemListLoader from "../Loader/ItemListLoader"
import { FaMicrosoft, FaCommentAlt, FaFileAlt, FaMoneyBillAlt } from "react-icons/fa"
import { MdGrade } from "react-icons/md"
import Circle from "react-circle"
import PremiumImageSmall from "../Images/PremiumImageSmall"
import WaitingImageSmall from "../Images/WaitingImageSmall"
import SidebarMenu from "../sidebarMenu"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

class Dashhoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loading:false,
            windowWidth: null,
            errors: {}
        };

        this.mediaQuery = {
            desktop: 1200,
            tablet: 768,
            phone: 576,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.setState({windowWidth: document.body.clientWidth})
        this.props.getCollectionOfUser()
        this.props.getApplications()
    }

    async onSubmit(e) {
        e.preventDefault();
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onUpgrade(e) {
        e.preventDefault()
        if( checkEmptyBiodata(this.props.profile) == false ) {
            MySwal.fire('Complete it your resume', 'Please complete it biodata, profile picture and resume', 'warning')
        } else {
            navigate('/user/upgrade')
        }
    }

    goHistory(e) {
        navigate('/user/history')
    }

    render() {
        const { errors } = this.state;
        const { faculties, compTypes, degrees, provinces, applications, profile } = this.props

        if ( !faculties || !profile || !compTypes || !degrees || !provinces ) {
            return (<UserLayout>
                <BoxCard bgcolor="bg-light" styles={{color:'#333'}} title="We care about data">
                    <ProfileLoader />
                </BoxCard>
                <BoxCard bgcolor="bg-light" styles={{color:'#333'}} title="Biodata">
                    <ProfileLoader />
                </BoxCard>
                <BoxCard bgcolor="bg-light" styles={{color:'#333'}} title="Work Experience">
                    <ItemListLoader />
                </BoxCard>
                <BoxCard bgcolor="bg-light" styles={{color:'#333'}} title="Educations">
                    <ItemListLoader />
                </BoxCard>
            </UserLayout>)
        }

        const {
            experinceList,
            educations,
            computer,
            languanges,
            work_levels,
            work_relations,
            categories
        } = profile

        let selections = []
        if( ! _.isEmpty(categories) ) {
            categories.map((item,key) => {
                selections.push(item.category_id)
            })
        }

        function getStatusProgress() {
            const num = calculateProgressResume(profile)
            if( num >= 0 && num <= 20 ) {
                return 'Less data, Please complete it all!'
            } else if( num > 20 && num <= 50 ) {
                return 'Slightly better, but it still less, please complete it data more detail'
            } else if( num > 50 && num <= 75 ) {
                return 'Good progress! make sure all data set completed!'
            } else if( num > 75 && num <= 95 ) {
                return 'Almost perfect! Please complete it if still no data'
            } else if( num > 95 && num <= 100 ) {
                return 'Perfect! You are cool! all data set up'
            }
        }

        const ppImage = this.props.pp ? this.props.pp : profile.jobseekers_image
        const resume = this.props.resume ? this.props.resume : profile.jobseekers_resume

        return (
            <UserLayout page="user">
                <SEO title="User Dashboard" />
                <UserContext.Consumer>
                    {cuser => (<div className="container" id="bodyLogin">
                        <div className="row">
                            <div className="col-10">
                                <BoxCard bgcolor="bg-light" width="12" padding={this.state.windowWidth < this.mediaQuery.phone ? 3 : 5} styles={{color:'#333'}} title="We care about data">
                                    <div className="row border-top pt-4">
                                        <div className={classnames('col-lg-2 col-12', {
                                                'text-center mb-3':this.state.windowWidth < this.mediaQuery.phone
                                            })}>
                                        
                                            <Circle
                                                animate={true} // Boolean: Animated/Static progress
                                                animationDuration="1s" // String: Length of animation
                                                responsive={false} // Boolean: Make SVG adapt to parent size
                                                size="110" // String: Defines the size of the circle.
                                                lineWidth="25" // String: Defines the thickness of the circle's stroke.
                                                progress={calculateProgressResume(profile)} // String: Update to change the progress and percentage.
                                                progressColor="#c81905" // String: Color of "progress" portion of circle.
                                                bgColor="#ecedf0" // String: Color of "empty" portion of circle.
                                                textColor="#6b778c" // String: Color of percentage text color.
                                                textStyle={{
                                                    font: 'bold 4rem Helvetica, Arial, sans-serif'
                                                }}
                                                percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                                                roundedStroke={false} // Boolean: Rounded/Flat line ends
                                                showPercentage={true} // Boolean: Show/hide percentage.
                                                showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                                                />
                                        </div>
                                        <div className="col-lg-5 col-12 border-right">
                                            <h5 className={classnames({
                                                'text-center':this.state.windowWidth < this.mediaQuery.phone
                                            })}>{getStatusProgress()}</h5>
                                            <ul className={classnames({
                                                'text-center':this.state.windowWidth < this.mediaQuery.phone
                                            })} style={{listStyle:"none", paddingLeft:0}}>
                                                <li>
                                                    {!checkEmptyBiodataFields(profile) ? <span className="font-weight-normal">Complete it your biodata</span> : <span className="text-muted" style={{textDecoration:"line-through"}}>Complete it your biodata</span>}
                                                </li>
                                                <li>
                                                    {_.isEmpty(ppImage)  ? <span className="font-weight-normal">Upload your latest profile picture</span> : <span className="text-muted" style={{textDecoration:"line-through"}} >Upload your latest profile picture</span>}
                                                </li>
                                                <li>
                                                    {_.isEmpty(resume) ? <span className="font-weight-normal">Upload your latest CV</span> : <span className="text-muted" style={{textDecoration:"line-through"}} >Upload your latest CV</span>}
                                                </li>
                                                <li>
                                                    {_.isEmpty(profile.experinceList) ? <span className="font-weight-normal">Insert min. 1 work experience</span> : <span className="text-muted" style={{textDecoration:"line-through"}} >Insert min. 1 work experience</span>}
                                                </li>
                                                <li>
                                                    {_.isEmpty(profile.educations) ? <span className="font-weight-normal">Insert min. 1 education</span> : <span className="text-muted" style={{textDecoration:"line-through"}}>Insert min. 1 education</span>}
                                                </li>
                                                <li>
                                                    {_.isEmpty(profile.computer) ? <span className="font-weight-normal">Insert min. 1 skill</span> : <span className="text-muted" style={{textDecoration:"line-through"}}>Insert min. 1 skill</span>}
                                                </li>
                                                <li>
                                                    {_.isEmpty(profile.languanges) ? <span className="font-weight-normal">Insert min. 1 language</span> : <span className="text-muted" style={{textDecoration:"line-through"}}>Insert min. 1 language skill</span>}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-5 col-12">
                                            <div className="mx-4 text-center">
                                                <h5>My Applications <span className="badge badge-secondary">{applications ? applications.length : 0 }</span></h5>
                                                <div className="mt-2 pb-3 border-bottom">
                                                    <Link to={'/user/application'} className="btn btn-outline-secondary"> View Details </Link>
                                                </div>

                                                <div className="pt-2">
                                                    {!profile.subscription && (<div className="">
                                                        <p>Upgrade JOBSMIGAS PREMIUM</p>
                                                        <a href="#" onClick={this.onUpgrade.bind(this)} className="btn btn-success"> Upgrade to Premium Member </a>
                                                    </div>)}

                                                    {(profile.subscription && profile.subscription.status == 'ok') && <div className="">
                                                        <MdGrade size="40" color="rgb(222, 138, 14)" />
                                                        <p>{profile.subscription.type}</p>
                                                        <a href="#" onClick={this.goHistory.bind(this)} className="btn btn-success mr-2">History</a> 
                                                    </div>}

                                                    {(profile.subscription && profile.subscription.status == 'pending') && <div className="">
                                                        <WaitingImageSmall />
                                                        <p><em><strong>Waiting - </strong></em> {profile.subscription.type}</p>
                                                        <a href="#" onClick={this.goHistory.bind(this)} className="btn btn-success mr-2">History</a>
                                                      
                                                    </div>}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </BoxCard>
                            
                                <div className="align-items-center justify-content-center bg-white">
                                    <Biodata cuser={cuser} provinces={provinces} profile={profile} />

                                    <JobInteresting selections={selections} />

                                    <WorkExperience 
                                        list={experinceList} 
                                        compTypes={compTypes}
                                        workLevels={work_levels} 
                                        workRelations={work_relations} />

                                    <Education 
                                        list={educations} 
                                        faculties={faculties}
                                        degrees={degrees} 
                                        provinces={provinces} />

                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col">
                                                    <Skill list={computer} />
                                                </div>
                                                <div className="col ml-5">
                                                    <Language list={languanges} />
                                                </div>
                                            </div>
                                        </div>
                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-2 justify-content-right">
                                <SidebarMenu message={profile.last_message} active="dashboard" />      
                            </div>
                            
                        </div>
                        
                    </div>)}
                </UserContext.Consumer>
            </UserLayout>
        )
    }
}

Dashhoard.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile,
    pp:state.user.pp,
    resume:state.user.resume,
    faculties: state.user.faculties,
    degrees: state.user.degrees,
    provinces: state.user.provinces,
    compTypes: state.user.company_types,
    applications: state.user.applications
});

export default connect(mapStateToProps, { getUser, getCollectionOfUser, getApplications })(Dashhoard);
