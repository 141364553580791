import React from 'react'
import ContentLoader from 'react-content-loader'

const ItemListLoader = props => {
  return (

    <ContentLoader
      height={50}
      width={500}
      speed={2}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="8" rx="0" ry="0" width="500" height="6" />
      <rect x="0" y="22" rx="0" ry="0" width="500" height="6" />
      <rect x="0" y="36" rx="0" ry="0" width="100" height="6" />

    </ContentLoader>
  )
}

export default ItemListLoader
