import React from 'react'
import ContentLoader from 'react-content-loader'

const ProfileLoader = props => {
  return (

    <ContentLoader
      height={120}
      width={400}
      speed={2}
      primaryColor="#d9d9d9"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="8" rx="0" ry="0" width="100" height="100" />
      <rect x="140" y="8" rx="0" ry="0" width="300" height="6" />
      <rect x="140" y="20" rx="0" ry="0" width="300" height="6" />
      <rect x="140" y="42" rx="0" ry="0" width="100" height="6" />

      <rect x="140" y="64" rx="0" ry="0" width="300" height="6" />
      <rect x="140" y="80" rx="0" ry="0" width="300" height="6" />
      <rect x="140" y="94" rx="0" ry="0" width="100" height="6" />

    </ContentLoader>
  )
}

export default ProfileLoader
