import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import classnames from 'classnames'
import { connect } from 'react-redux'
import { forEach, map, isEmpty, has, kebabCase } from 'lodash'
import { FaUserCircle, FaEdit, FaTimes } from "react-icons/fa"
import {  makeArrayKeyLabel, years, createNotification } from "../../utils/helpers"
import { getUser  } from "../../actions/auth"
import lang from "../../langs"
import BoxCard from '../Common/BoxCard'

class MyApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {}
        };
    }

    componentDidMount() {
        
    }

    render() {
      
        const { errors } = this.state

       return (
        <BoxCard title={'My Application'} width="12" withAction={false}>
            <ul class="list-group border-none ml-0">
                {this.props.list && this.props.list.map(item => (
                    <>
                        <li className="list-group-item align-items-center">
                            <h4 className="mb-1">{item.job.job_title}</h4>
                            <p className="font-weight-bold text-danger mb-1">{item.job.employers_obj.employers_company_name}</p>
                            <div className="row">
                                <div className="col">
                                    <p className="lead mb-1">{ has(item.job.provinsi, 'lokasi_nama') ? item.job.provinsi.lokasi_nama : ''}</p>
                                </div>
                                <div className="col">
                                    <p className="text-right" style={{flexGrow:2}}>
                                        <>
                                        {item.status == 'received' && <span className="badge badge-primary">{'Received'}</span>}
                                        {item.status == 'inteview' && <span className="badge badge-info">{'Interview'}</span>}
                                        {item.status == 'rejected' && <span className="badge badge-danger">{'Rejected'}</span>}
                                        {item.status == 'completed' && <span className="badge badge-success">{'Accepted'}</span>}
                                        </>
                                    </p>
                                </div>
                            </div>
                            <p>
                                <Link style={{fontSize:12}} className="btn btn-sm btn-outline-danger" to={`/job/detail/${item.job.job_id}_${kebabCase(item.job.job_title)}`} state={{ id: item.job.job_id }}>
                                    View Detail
                                </Link>
                            </p>
                            
                        </li>

                    </>
                ))}
                {!this.props.list || isEmpty(this.props.list) && <li className="list-group-item d-flex justify-content-center align-items-center">
                        <p className="" style={{padding:'30px 0px'}}>
                            No application list
                        </p>
                </li> }
            </ul>
        </BoxCard>)
    }

}


MyApplication.propTypes = {
    errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors
});


export default connect(mapStateToProps, { getUser })(MyApplication);