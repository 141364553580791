import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { isEmpty, indexOf, pull, difference } from 'lodash'
import {  createNotification } from "../../utils/helpers"
import { getCategory } from "../../actions/category"
import { saveJobInteresting } from "../../actions/user"
import { getUser  } from "../../actions/auth"
import BoxCard from '../Common/BoxCard'
import TagLoader from "../Loader/TagLoader"

class JobInteresting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adding:false,
            loading:false,
            saving:false,
            removing:false,
            items:[],
            oldItems:[],
            errors: {}
        };
    }

    componentDidMount() {
        this.setState({
            items:this.props.selections
        })
        this.props.getCategory()
    }

    onSelect(id, e) {
        let items = this.state.items
        if( indexOf(items, id) > -1 ) {
            const d = pull(items, id)
            this.setState({
                items:d
            })
        } else {
            items.push(id)
            this.setState({
                items:items
            })
        }
    }

    onSave(e) {
        e.preventDefault()
        this.setState({saving:true}, () => {
            this.props.saveJobInteresting({
                categories:this.state.items,
                action:''
            }).then(res=>{
                createNotification('success', res.messages)
                this.setState({
                    saving:false
                })
                setTimeout(()=>{
                    this.props.getUser()
                }, 3000)
                
            })
        })
       
    }

    render() {
      
        const { errors } = this.state

        if( ! this.props.category ) {
            return <TagLoader />
        }

        return (
        <BoxCard title={'Job Category'} width="12">
            { ( isEmpty(this.props.selections) || !isEmpty(difference(this.props.selections, this.state.items)) || !isEmpty(difference(this.state.items, this.props.selections)) ) && <div className="alert alert-danger mb-4">
                You seamed do changes, Please update it all <a href="#" className="btn btn-primary btn-sm" disabled={this.state.saving} onClick={this.onSave.bind(this)}>{this.state.saving ? 'Saving...' : 'Save'}</a>
            </div>}
            <div className="ml-0">
                <p className="mb-2">Please select job category favorite, min. 1 category</p>
                {this.props.category && this.props.category.map(item => (
                    <React.Fragment key={item.id} >
                        <button disabled={this.state.saving} className={classnames('btn mr-2 mb-2', {
                            'btn-outline-secondary':indexOf(this.state.items, item.id) == -1,
                            'btn-secondary':indexOf(this.state.items, item.id) > -1
                        })} type={'button'} onClick={this.onSelect.bind(this, item.id)}>
                            {item.nama}
                        </button>
                    </React.Fragment>
                ))}
                
            </div>
        </BoxCard>)

    }

}


JobInteresting.propTypes = {
    errors: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    errors: state.errors,
    category: state.user.category
});


export default connect(mapStateToProps, { getCategory, saveJobInteresting, getUser })(JobInteresting);