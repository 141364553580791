import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import { Link, navigate } from "gatsby"
import { connect } from 'react-redux'
import { getUser } from '../../actions/auth';
import { checkoutValidate } from "../../validations/user"
import { createNotification, currency, isPremiumProcess } from "../../utils/helpers"
import UserContext from "../../context/CurrentUserContext"
import UserLayout from "../UserLayout"
import { map } from 'lodash'
import SEO from "../seo"
import BoxCard from "../Common/BoxCard"
import moment from "moment"

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:false,
            agree:false,
            scriptLoaded:false,
            token:'',
            first_name:'',
            last_name:'',
            email:'',
            phone:'',
            city:'',
            address:'',
            postal_code:'',
            errors: {},
            messageType:'',
            message:'',
        };
        this.days = 91;
        this.extra = 61;
        this.startDate = moment().add(1, 'days').format("DD MMM YYYY");
        this.endDate = moment().add( (this.days+this.extra), "days").format('DD MMM YYYY');
    }

    componentDidMount() {
        this.props.getUser()
            .then(profile=> {
                if( isPremiumProcess(profile) ) {
                    createNotification('warning', 'Sorry! Your are under processing or had already to be Premium Member')
                    if( profile.email != 'dian.afrial86@gmail.com') {
                        navigate('/user/dashboard')
                    }
                }

                this.setState({
                    first_name:profile.jobseekers_firstname,
                    last_name:profile.jobseekers_lastname,
                    email:profile.email,
                    phone:profile.jobseekers_phone,
                    address:profile.jobseekers_address,
                    postal_code:profile.jobseekers_zipcode,
                })
            })

        const urlParams = new URLSearchParams(this.props.location.search);
        if( urlParams.get('error') == 'payment' ) {
            this.setState({
                messageType:'alert-danger',
                message:'Your payment is unsuccessful or an error occurs during payment! Please try again'
            })
        }
    }

    onSubmit(e) {
        const userData = {
            phone:this.state.phone,
            city:this.state.city,
            email:this.state.email,
            address:this.state.address,
            first_name:this.state.first_name,
            last_name:this.state.last_name,
            postal_code:this.state.postal_code
        }

        checkoutValidate(userData)
            .then(res=>{
                navigate('/user/payment', {
                    state:userData
                })
            })
            .catch(err=>{
                this.setState({ errors: err })
                createNotification('error', map(err, (val) => { return val }).join("\n\n\n"))
                return false;
            })
    }

    onChange(e) {
        this.setState({
            [e.target.name]:e.target.value
        })
    }


    render() {
        const { errors } = this.state;
        const { profile } = this.props
    
        return (
            <UserLayout page="user">
                <SEO title="Checkout" />
                <UserContext.Consumer>
                    {cuser => (<div className="container" id="upgradePayment">
                        <BoxCard bgcolor="bg-light" styles={{color:'#333'}}>
                            <div className="row border-top pt-4">
                                <div className="col-12 text-center">
                                    <h2 className="text-center mb-4"> Purchase Order </h2>
                                    <p className="mb-5">
                                        Thanks for subscription Membership Premium at Jobsmigas.com. 		
                                    </p>
                                </div>

                                {this.state.message && <div  className="col-12">
                                    <div className={classnames("alert", {
                                        [this.state.messageType]:this.state.messageType ? true:false
                                    })}>
                                        <p>{this.state.message}</p>
                                    </div>
                                </div>}
                                
                                <div className="col 12">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Product Name</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong>Subscription Premium Membership</strong> <br />for 3 months + <span>{`Bonus free 2 months membership.`}</span> <br />( {this.startDate} s.d. {this.endDate} )</td>
                                                <td>1</td>
                                                <td>{profile ? currency(profile.upgrade_info.fee) : '-' }</td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan="2" className="font-weight-bold">Total</td>
                                                <td className="font-weight-bold">{profile ? currency(profile.upgrade_info.fee) : '-' }</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12 mt-5" style={{marginTop:40}}>
                                    <h2 className="text-center mb-4"> Customer Details </h2>
                                    
                                    <div className="form-group row">
                                        <div className="col-4">
                                            <label htmlFor="name" className="font-weight-bold">Name</label>
                                        </div>
                                        <div className="col-8">
                                            <div className="row">
                                                <div className="col-6">
                                                    <input type="text" name="first_name" 
                                                        className={classnames('form-control input-lg', {
                                                            'is-invalid': errors.first_name
                                                        })}
                                                        placeholder={"First name"}
                                                        value={this.state.first_name}
                                                        onChange={this.onChange.bind(this)} 
                                                        id="first_name" />
                                                    {errors.first_name && <div className="invalid-feedback">{errors.first_name}</div>}
                                                </div>
                                                <div className="col-6">
                                                    <input type="text" name="last_name" 
                                                        className={classnames('form-control input-lg', {
                                                            'is-invalid': errors.last_name
                                                        })}
                                                        placeholder={"Last name"}
                                                        value={this.state.last_name}
                                                        onChange={this.onChange.bind(this)} 
                                                        id="last_name" />
                                                    {errors.last_name && <div className="invalid-feedback">{errors.last_name}</div>}
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-4">
                                            <label htmlFor="email" className="font-weight-bold">Email Address</label>
                                        </div>
                                        <div className="col-8">
                                            <input type="email" name="email" 
                                                className={classnames('form-control input-lg', {
                                                    'is-invalid': errors.email
                                                })}
                                                placeholder={"Valid Email Address"}
                                                value={this.state.email}
                                                onChange={this.onChange.bind(this)} 
                                                id="email" />
                                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                        </div>
                                    </div>
                                   
                                    <div className="form-group row">
                                        <div className="col-4">
                                            <label htmlFor="phone" className="font-weight-bold">Phone No.</label>
                                        </div>
                                        <div className="col-8">
                                            <input type="text" name="phone" 
                                                className={classnames('form-control input-lg', {
                                                    'is-invalid': errors.phone
                                                })}
                                                placeholder={"Phone Number"}
                                                value={this.state.phone}
                                                onChange={this.onChange.bind(this)} 
                                                id="phone" />
                                            {errors.phone && <div className="invalid-feedback">{errors.phone}</div>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-4">
                                            <label htmlFor="address" className="font-weight-bold">Address</label>
                                        </div>
                                        <div className="col-8">
                                            <textarea name="address" 
                                                className={classnames('form-control input-lg', {
                                                    'is-invalid': errors.address
                                                })}
                                                placeholder={"Address"}
                                                value={this.state.address}
                                                onChange={this.onChange.bind(this)} 
                                                id="address" />
                                            {errors.address && <div className="invalid-feedback">{errors.address}</div>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-4">
                                            <label className="control-label font-weight-bold" htmlFor="city">City</label>
                                        </div>
                                        <div className="col-8">
                                            <input type="text" name="city" 
                                                className={classnames('form-control input-lg', {
                                                    'is-invalid': errors.city
                                                })}
                                                placeholder={"Enter City"}
                                                value={this.state.city}
                                                onChange={this.onChange.bind(this)} 
                                                id="city" />
                                            {errors.city && <div className="invalid-feedback">{errors.city}</div>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-4">
                                            <label htmlFor="postal_code" className="font-weight-bold">Postal Code</label>
                                        </div>
                                        <div className="col-8">
                                            <input type="text" name="postal_code" 
                                                className={classnames('form-control input-lg', {
                                                    'is-invalid': errors.postal_code
                                                })}
                                                placeholder={"Postal Code"}
                                                value={this.state.postal_code}
                                                onChange={this.onChange.bind(this)} 
                                                id="postal_code" />
                                            {errors.postal_code && <div className="invalid-feedback">{errors.postal_code}</div>}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="row mt-3 border-top justify-content-between text-center">
                                <div className="col-4 ">
                                    <Link className="btn mt-4 btn-outline-secondary btn-lg" to={'/user/upgrade'}>Back</Link>
                                </div>
                                <div className="col-4">
                                    {profile && <button type="button" onClick={this.onSubmit.bind(this)}
                                        className="btn mt-4 mb-2 btn-danger btn-lg">Checkout</button>}
                                </div>
                            </div>
                        </BoxCard>
                       
                    </div>)}
                </UserContext.Consumer>
            </UserLayout>
        )
    }
}

Cart.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    applications: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors,
    profile: state.user.profile,
    applications: state.user.applications
});

export default connect(mapStateToProps, { getUser })(Cart);
