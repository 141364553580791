import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactLoading from "react-loading"
import { Link, navigate } from "gatsby"
import classnames from 'classnames'
import { connect } from 'react-redux'
import {  isEmpty, isNil, has, trim, split, last, indexOf } from 'lodash'
import { FaArrowAltCircleRight, FaExclamationCircle } from "react-icons/fa"
import { showErrors, isPremium, isPremiumPending, isPremiumProcess, cacheStore } from "../../utils/helpers"
import { getUser } from "../../actions/auth"
import { fetchMessageBoxes, fetchMessageBox, fetchMessageChats, postMessageChats } from "../../actions/message"
import BoxCard from '../Common/BoxCard'
import ChatLoader from "../../components/Loader/ChatLoader"

class Chatroom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            box:null,
            boxes:null,
            loadingChat:false,
            postLoading: false,
            newChat:false,
            message:"",
            errors: {},
            error:null
        };

        this.sidebarRef = React.createRef();
        this.chatBoxRef = React.createRef();
    }

    componentDidMount() {

      let queryString = this.props.location.search
      if( queryString == '' ) {
          let arrQuery  = split(this.props.location.href, '?')
          queryString = last(arrQuery)
      }
      let params = new URLSearchParams(queryString);

      if (params.get("box_id")) {
        this.props
          .fetchMessageBox(params.get("box_id"))
          .then(item => {
            this.setState({
              box: item
            });
            this._renderHistoryChats(item);
          })
          .catch(err => {
            if (has(err, "data")) {
              this.setState({
                error: showErrors(err.data)
              });
            } else {
              this.setState({
                error: "Not found Message box"
              });
            }
          });
      }

      this.loadOpenMessage()
    }

    static getDerivedStateFromProps(props, state) {
      if( props.message.fetching == true && props.message.fetching != state.newChat ) {
        return { newChat: true }
      } else {
        return { newChat: false }
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      // If we have a snapshot value, we've just added new items.
      // Adjust scroll so these new items don't push the old ones out of view.
      // (snapshot here is the value returned from getSnapshotBeforeUpdate)
      if( prevProps.message.fetching ) {
        this._scrollToBottom()
      } 
    }

    onChange(e) {
      this.setState({
        message: e.target.value
      });
    }
    
    loadMessageBox(item, e) {
      navigate('/user/message?box_id=' + item.id, { replace:true } )
      this._renderHistoryChats(item);
    }

    loadOpenMessage() {
      this.props.fetchMessageBoxes().then(res=>{
          this.setState({
              boxes:res.list
          })
      })
    }  
    
    _renderHistoryChats(item) {
      this.setState({ loadingChat: true, box: item, error: null }, async () => {
        await this.props
          .fetchMessageChats(item.id)
          .then(res => {
            this.setState({
              loadingChat: false
            });
            // make scroll to bottom
            this._scrollToBottom();
          })
          .catch(err => {});
      });
    }

    postMessage(e) {
      this.setState(
        {
          postLoading: true,
          loadingChat: true
        },
        () => {
          this._scrollToBottom();
        }
      );
  
      if (this.state.box && !isEmpty(trim(this.state.message))) {
        const box = this.state.box;
        this.props
          .postMessageChats(box.id, {
            content: this.state.message,
            user_id: this.props.user.id
          })
          .then(res => {
            this.props
              .fetchMessageChats(box.id)
              .then(res => {
                this.setState({
                  loadingChat: false,
                  postLoading: false,
                  message: ""
                });
                this._scrollToBottom();
              })
              .catch(err => {});
          })
          .catch(err => {});
      }
    }

    _scrollToBottom() {
      let messageBody = this.chatBoxRef.current;
      messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
    }

    getFullName(jobseeker) {
        return (
          jobseeker.jobseekers_firstname +
          " " +
          (jobseeker.jobseekers_lastname ? jobseeker.jobseekers_lastname : "")
        );
    }

    render() {
        const { chats, fetching, new_chats } = this.props.message
        const { errors, boxes, box } = this.state
        let renderable, boxCount;

        if( boxes === null ) {
          return (
            <BoxCard title={'Message Box'} width="12" padding={4} withAction={false}>
              <ChatLoader />
            </BoxCard>
          )
        }

        if (!isEmpty(boxes)) {
            boxCount = boxes.length;
        }

        const displyaStatus = d => {
            if (d.status === "received") {
                return <span className="badge badge-primary">Received</span>
            } else if (d.status === "unqualified") {
                return <span className="badge badge-secondary">Unqualified</span>
            } else if (d.status === "complete") {
                return <span className="badge badge-success">Complete</span>
            } else if (d.status === "inteview") {
                return <span className="badge badge-warning">Interview</span>
            }
        }

        const isOpenBoxComment = () => {
          return box && has(box, "status") && box.status === "open";
        };

       return (
        <>
          <BoxCard title={'Message Box'} width="12" padding={4} withAction={false}>
            <div className="row">
              <div className="col-12">
                { isEmpty(boxes) && !isPremium(this.props.profile) && <div className="alert alert-warning mb-3">
                  <p>This feature is locked! Please upgrade your membership to Premium</p>
                </div>}
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-4 d-none d-lg-block">
                <div className="card" id="appList">
                  <div className="card-body"
                    style={
                      boxCount && boxCount > 4
                        ? { overflowY: "scroll" }
                        : {}
                    }
                    id="sidebarBody"
                    ref={this.sidebarRef}
                  >
                    <div class="list-group list-group-flush">
                      {!isNil(new_chats) && boxes && boxes.map((item, idx) => (
                        <button
                          onClick={this.loadMessageBox.bind(this, item)}
                          type="button"
                          key={idx}
                          className={classnames(
                            "list-group-item list-group-item-action",
                            {
                              active:
                                this.state.box &&
                                has(this.state.box, "id") &&
                                this.state.box.id === item.id
                            }
                          )}
                        >
                          <div className="row">
                            <div className="col-9">
                              <h6>{isPremium(this.props.profile) ? item.employer.employers_company_name : 'Recruiter'}</h6>
                            </div>
                            <div className="col-3">
                              {indexOf(new_chats, item.id) > -1 && <span className="badge badge-danger">new</span> }
                              {item.status == 'closed' && <span className="badge badge-secondary">closed</span> }
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <span className="text-muted">
                                {item.job.job_title}
                              </span>
                            </div>
                          </div>
                        </button>
                      ))}
                      {isEmpty(boxes) && (
                        <div
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            alignContent: "center",
                            textAlign: "center",
                            margin: "40px auto"
                          }}
                        >
                          No chats
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-sm-8">
                <div className="card">
                  <div className="card-title"
                    className="d-flex flex-row p-3"
                    style={{ marginBottom: 0, borderBottom: "1px solid #ddd" }}
                  >
                    <div className="flex-grow-1">
                      {(isPremium(this.props.profile) && box) ? (
                        <React.Fragment>
                          <h5>
                            {box.employer.employers_company_name}{" "}
                          </h5>
                          <p
                            style={{
                              fontSize: 16,
                              marginBottom: 0,
                              fontWeight: "normal"
                            }}
                          >
                            {box.employer.province.lokasi_nama} / {box.employer.country.country} - {box.employer.company_type.company_type}
                          </p>
                        </React.Fragment>
                      ) : (
                        "Chat Box"
                      )}
                    </div>
                    
                  </div>
                  <div className="card-body" style={{ padding: 0 }}>
                    <div
                      id="messageBody"
                      ref={this.chatBoxRef}
                      style={ (isPremium(this.props.profile) && box) ? {
                        position: "relative",
                        boxShadow: "inset 0px 0px 5px 4px rgba(0,0,0,0.046)",
                        height: 400,
                        paddingTop: 20,
                        background: "#f5f5f5",
                        paddingBottom: 20,
                        overflowY: "scroll"
                      } : {
                        position: "relative",
                        height: 517,
                      }}
                    >
                      {box &&
                        chats && isPremium(this.props.profile) && 
                        chats.list.map((chat, idx) => (
                          <div
                            style={{ margin: 0 }}
                            key={idx}
                            className="row justify-content-end chat-wrapper"
                          >
                            {chat.user_id && (
                              <div className="chat-quote col-12 by-user">
                                <div>
                                  <h6>
                                    {box.jobseeker.jobseekers_firstname}{" "}
                                    <span style={{ fontSize: 12, color: "#aaa" }}>
                                      &bull; {chat.date}
                                    </span>
                                  </h6>
                                  {chat.message_content}
                                </div>
                              </div>
                            )}

                            {!chat.user_id && (
                              <div className="chat-quote d-flex flex-row col-12 by-admin">
                                <div>
                                  <h6>
                                    {box.employer.employers_company_name}{" "}
                                    <span style={{ fontSize: 12, color: "#aaa" }}>
                                      &bull; {chat.date}
                                    </span>
                                  </h6>
                                  {chat.message_content}
                                </div>
                              </div>
                            )}

                            {fetching && <div
                                style={{
                                  width: 100,
                                  height: 20,
                                  bottom: 10,
                                  left: 0,
                                  right: 0,
                                  margin: "10px auto"
                                }}
                              >
                                <ReactLoading
                                  width={40}
                                  height={10}
                                  type="bars"
                                  color="#aaa"
                                />
                            </div>}
                          </div>
                        ))
                      }

                      {box && chats && !isPremium(this.props.profile) && <div
                          style={{ margin: 0, height:400 }}
                          className="row justify-content-center align-items-center align-content-center text-center"
                        >
                          <div className="col-12">
                              <FaExclamationCircle size={80} color={"#aaa"} /><br/><br/>
                              <h5>
                                {!isPremiumPending(this.props.profile) ? 'You are not Premium Member! Please upgrade to Premium Membership' : 'Your Premium Membership still has no active, Please keep a moment...'}
                              </h5>
                              {!isPremiumPending(this.props.profile) && <Link to={'/user/upgrade'} className="btn btn-md btn-primary" >Upgrade</Link>}
                          </div>
                        </div>}

                      {box == null && <div
                            style={{ margin: 0, height:400 }}
                            className="row justify-content-center align-items-center align-content-center text-center"
                        >
                          <div className="col-12">
                              <FaExclamationCircle size={80} color={"#aaa"} /><br/><br/>
                              <h5>
                                Please select company at left box to begin conversation
                              </h5>
                          </div>
                        </div>}

                      {isPremium(this.props.profile) && this.state.postLoading && (
                        <div
                          style={{
                            width: 100,
                            height: 20,
                            bottom: 10,
                            left: 0,
                            right: 0,
                            margin: "10px auto"
                          }}
                        >
                          <ReactLoading
                            width={40}
                            height={10}
                            type="bars"
                            color="#aaa"
                          />
                        </div>
                      )}

                      {this.state.error && (
                        <div
                          style={{
                            flex: 1,
                            justifyContent: "center",
                            alignContent: "center",
                            textAlign: "center",
                            margin: "40px auto"
                          }}
                        >
                          <FaExclamationCircle size={40} color="black" />{" "}
                          <br />
                          <br />
                          <h5>{this.state.error}</h5>
                        </div>
                      )}
                    </div>
                  </div>
                  {isPremium(this.props.profile) && isOpenBoxComment() && (
                    <div className="card-footer">
                      <div className="row no-gutters">
                        <div className="col-11">
                          <textarea
                            id="messageArea"
                            rows={2}
                            style={{ minHeight: 36, maxHeight: 105 }}
                            className="form-control"
                            value={this.state.message}
                            placeholder={"Ketikkan Pesan..."}
                            onKeyPress={e => {
                                if( e.charCode === 13 ) {
                                  this.postMessage(e)
                                }
                            }}
                            onChange={this.onChange.bind(this)}
                          />
                        </div>
                        <div className="col-1">
                          <button
                            type="button"
                            disabled={this.state.postLoading}
                            className="btn btn-block btn-success ml-2"
                            onClick={this.postMessage.bind(this)}
                            style={{height:58}}
                          >
                            <FaArrowAltCircleRight color="white" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  { this.state.box && !isOpenBoxComment() && (
                    <div className="card-footer" style={{height: 85 }}>
                      <div className="row no-gutters">
                          <div className="col-12">
                              <p className="lead" style={{paddingTop:10}}>
                              <FaExclamationCircle size={20} color={"#aaa"} /> The conversation is automatic closed because Your application set to <em>{this.state.box.application.status}</em>  </p>
                          </div>
                      </div>
                    </div>
                  ) }
                </div>
              </div>
            </div>
          </BoxCard>
        </>)
    }

}


Chatroom.propTypes = {
    errors: PropTypes.object.isRequired,
    message: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    errors: state.errors,
    message: state.message
});


export default connect(mapStateToProps, { getUser, fetchMessageBoxes, fetchMessageBox, fetchMessageChats, postMessageChats })(Chatroom);